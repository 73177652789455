import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { login } from "../utils/authService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import CryptoBird from "../img/cryptobird_logo_name.png";
const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    const crdbToken = localStorage.getItem("crbd_token");

    if (crdbToken) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const isEmpty = inputValue.username === "" || inputValue.password === "";

  // const { username, password } = inputValue;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) => {
    toast.error(err, {
      position: "top-right",
    });
    setIsLoading(false);
    setInputValue({ username: "", password: "" });
  };

  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-left",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data } = await login(inputValue);

      const { success, message, crbd_token } = data;

      if (success) {
        localStorage.setItem("crbd_token", crbd_token);
        handleSuccess(message);
        setIsLoading(false);
        navigate("/dashboard");
      } else {
        handleError(message);
      }
    } catch (error) {
      console.log(error.response.status);
    }
    setInputValue({
      ...inputValue,
      username: "",
      password: "",
    });
  };

  const style = {
    backgroundColor: "transparent",
    borderRadius: "0.5rem",
    padding: "0.5rem",
    color: "grey",
    border: "1px solid #333",
    outline: "none",
  };

  return (
    <div
      className="App-header"
      style={{
        background: "#000",
        padding: "2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "90vh", // Adjust the height to fill the entire viewport
        outline: "none",
      }}
    >
      <img
        src={CryptoBird}
        style={{ height: "350px", marginBottom: "20px" }} // Adjust the margin bottom as needed
        alt="cryptobird"
      />
      <form
        onSubmit={handleSubmit}
        style={{ width: "100%", maxWidth: "300px" }}
      >
        {" "}
        {/* Adjust the width as needed */}
        <div className="form-group">
          <input
            type="text"
            value={inputValue.username}
            className="form-control"
            name="username"
            placeholder="Username"
            onChange={handleOnChange}
            style={{
              fontSize: "1.6rem",
              padding: "0.5rem",
              outline: "none",
              background: "#000",
              border: "1px solid #333",
              color: "#fff",
              margin: "5px",
              outline: "none",
            }} // Simplified padding
          />
        </div>
        <div className="form-group">
          <input
            value={inputValue.password}
            type="password"
            className="form-control"
            name="password"
            placeholder="Password"
            onChange={handleOnChange}
            style={{
              fontSize: "1.6rem",
              padding: "0.5rem",
              outline: "none",
              background: "#000",
              border: "1px solid #333",
              color: "#fff",
              margin: "5px",
            }} // Simplified padding
          />
        </div>
        <button
          className="btn btn-block"
          type="submit"
          disabled={isEmpty}
          style={{
            fontSize: "1.6rem",
            width: "100%",
            background: "#333",
            color: "#fff",
            border: "1px solid #333",
            padding: "0.5rem", // Simplified padding
            margin: "5px",
          }}
        >
          {isLoading ? (
            <FontAwesomeIcon icon={faGear} size="lg" spin />
          ) : (
            "Login "
          )}
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Login;
