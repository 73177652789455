import React, { useState } from "react";
import moment from "moment";
import Pill from "../elements/Pill";
import Spinner from "../../img/ball.gif";
import { findDateRangeProspects } from "../../utils/dataService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faThumbsUp,
  faThumbsDown,
  faSackDollar,
} from "@fortawesome/free-solid-svg-icons";

const defaultStartDate = "2022-01-01";
const defaultEndDate = moment().subtract(1, "days").format("YYYY-MM-DD");

const Tester = () => {
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [takeProfit, setTakeProfit] = useState(3);
  const [stopLoss, setStopLoss] = useState(0.5);
  const [symbol, setSymbol] = useState("TWENTY");
  const [isLoading, setIsloading] = useState(false);
  const [orders, setOrders] = useState([]);

  const handleDateChange = ({ target }) => {
    setStartDate(target.value);
  };

  const handleSLChange = ({ target }) => {
    setStopLoss(target.value);
  };

  const handleSymbolChange = ({ target }) => {
    setSymbol(target.value);
  };

  const handleTPChange = ({ target }) => {
    setTakeProfit(target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsloading(true);
    const testData = { startDate, takeProfit, stopLoss, symbol };
    const { data } = await findDateRangeProspects(testData);

    if (data) {
      const { orders } = data;
      setOrders(orders);
      setIsloading(false);
    }
  };

  const calculateDuration = (order) => {
    const { orderDate, closeTime } = order;
    const startTime = moment.utc(orderDate);
    const endTime = moment.utc(closeTime);

    const duration = moment.duration(endTime.diff(startTime));
    const durationFormatted = duration.asDays().toFixed(0);

    return durationFormatted;
  };

  if (!isLoading) {
    const profits = orders.filter((order) => order.status === "profit");
    const losses = orders.filter((order) => order.status === "loss");

    const profitsValue = profits.length * ((2000 * takeProfit) / 100);
    const lossesValue = losses.length * ((2000 * stopLoss) / 100);

    const result = profitsValue - lossesValue;
    const resultLVR5 = profitsValue * 5 - lossesValue * 5;

    return (
      <div className="container mt-3 ">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-6">
              <label>Token(s)</label>
              <input
                type="text"
                className="form-control"
                onChange={(e) => handleSymbolChange(e)}
                value={symbol}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <label>Start Date</label>
              <input
                type="date"
                min={defaultStartDate}
                max={defaultEndDate}
                className="form-control"
                onChange={(e) => handleDateChange(e)}
              />
            </div>
          </div>
          <div className="row mt-3">
            {" "}
            <div className="col-4">
              <label>Stop Loss</label>
              <input
                type="number"
                placeholder="SL (%)"
                className="form-control"
                onChange={(e) => handleSLChange(e)}
                value={stopLoss}
                step="0.1"
              />
            </div>
            <div className="col-4">
              <label>Take Profit</label>
              <input
                type="number"
                placeholder="TP (%)"
                value={takeProfit}
                className="form-control"
                onChange={(e) => handleTPChange(e)}
                step="0.1"
              />
            </div>
          </div>
          <div className="row">
            {" "}
            <div className="col-2 mt-3">
              <button className="btn btn-dark text-light btn-lg" type="submit">
                Verify
              </button>
            </div>
          </div>
        </form>
        {!isLoading &&
          orders.length === 0 &&
          startDate !== defaultStartDate && (
            <p className="mt-3 text-secondary">No signals yet found.</p>
          )}
        {!isLoading && orders && orders.length > 0 && (
          <div>
            <div className="Orders-header">
              <div className="m-3" style={{ fontSize: "1.5rem" }}>
                <h3>Results:</h3>
                <div className="mt-3">
                  <div>
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                      style={{ color: "lightgreen" }}
                    />
                    <span className="ms-2">
                      x{profits.length} (${profitsValue})
                    </span>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faThumbsDown}
                      style={{ color: "#FF8080" }}
                    />
                    <span className="ms-2">
                      x{losses.length} (${lossesValue})
                    </span>
                  </div>
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faSackDollar}
                    style={{ color: result > 0 ? "lightgreen" : "#FF8080" }}
                  />
                  <span className="ms-2">
                    ${Math.abs(result)} (
                    <span style={{ color: "grey" }}>LVR5:</span> $
                    {Math.abs(resultLVR5)})
                  </span>
                </div>
              </div>
              <div style={{ padding: "10px" }} className="table-responsive">
                <table className="table table-dark text-light mt-3 rounded rounded-3 overflow-hidden">
                  <thead>
                    <tr>
                      {/* <th scope="col">#</th> */}
                      <th scope="col">Token</th>
                      <th scope="col">Entry</th>
                      <th scope="col">Date</th>

                      <th scope="col">Status</th>
                      <th scope="col">Days</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.length > 0 &&
                      orders.map((order, index) => {
                        return (
                          <tr key={index}>
                            {/* <th scope="row">{index + 1}</th> */}
                            <td>
                              <span>
                                <Pill
                                  value={order.name.split("USDT")[0]}
                                  className="ms-3"
                                  theme="info"
                                />
                              </span>
                              <Pill
                                value={order.type.toUpperCase()}
                                className="ms-3"
                                theme={
                                  order.type === "long" ? "success" : "danger"
                                }
                              />
                            </td>
                            <td>${order.value.toFixed(4)}</td>
                            <td>
                              {order.orderDate.split(" ")[0]}{" "}
                              <span style={{ color: "gray" }}>
                                {order.orderDate.split(" ")[1].slice(0, 5)}
                                {order.orderDate.split(" ")[2]}
                              </span>
                            </td>
                            {/* <td>
                              {order.type === "short" ? (
                                <FontAwesomeIcon icon={faArrowDown} style={{ color: "#FF8080" }} />
                              ) : (
                                <FontAwesomeIcon icon={faArrowUp} style={{ color: "lightgreen" }} />
                              )}
                            </td> */}

                            <td>
                              {order.active === true ? (
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  style={{ color: "lightgrey" }}
                                />
                              ) : order.status === "profit" ? (
                                <FontAwesomeIcon
                                  icon={faThumbsUp}
                                  style={{ color: "lightgreen" }}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faThumbsDown}
                                  style={{ color: "#FF8080" }}
                                />
                              )}
                            </td>
                            <td>{calculateDuration(order)}d</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        className="container mt-3 d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <img src={Spinner} alt="spinner" />
      </div>
    );
  }
};

export default Tester;
