import React, { useState, useEffect } from "react";
import "./UserSettings.css";
import "./Portfolio.css";
import { getUser, putUser, switchCryptoBird } from "../../utils/users";
import Spinner1 from "../../img/switchLoading.gif";
import Spinner from "../../img/anime10.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faCircleInfo,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
// import Binance from "../../img/binance.png";
// import Kucoin from "../../img/kucoinLogo.png";
// import Lcx from "../../img/lcxWhite.png";
import { calculateAverageLossPercentage } from "../../utils/finances";
import { largeMoneyValueFormatter } from "../../utils/helpers";
import Portfolio from "./Portfolio";
import InfoTile from "../elements/InfoTile";

const UserSettings = () => {
  const [user, setUser] = useState({});
  const [isLoading, setIsloading] = useState(true);
  const [isCryptoBirdActivated, setIsCryptoBirdActivated] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [avgRisk, setAvgRisk] = useState(0);
  const [singleIvestmentInfo, setSingleInvestmentInfo] = useState(null);
  const [budgetAllocationInfo, setBudgetAllocationInfo] = useState(null);
  const [rebalancerInfo, setRebalancerInfo] = useState(null);
  const [leverageInfo, setLeverageInfo] = useState(null);
  const [averageRiskInfo, setAverageRiskInfo] = useState(null);
  const [switchLoading, setSwitchLoading] = useState(false);

  const handleShowLeverageInfo = () => {
    setLeverageInfo(true);
  };

  const handleShowAverageRiskInfo = () => {
    setAverageRiskInfo(true);
  };

  const handleShowSingleInvestmentInfo = () => {
    setSingleInvestmentInfo(true);
  };

  const handleShowBudgetAllocationInfo = () => {
    setBudgetAllocationInfo(true);
  };

  const handleShowRebalancerInfo = () => {
    setRebalancerInfo(true);
  };

  const handleCloseInfo = () => {
    setSingleInvestmentInfo(false);
    setBudgetAllocationInfo(false);
    setRebalancerInfo(false);
    setLeverageInfo(false);
    setAverageRiskInfo(false);
  };

  const handleToggle = async () => {
    const confirmation = window.confirm(
      `You are going to ${
        isCryptoBirdActivated ? "DISABLE" : "ENABLE"
      } CryptoBird?`
    );

    if (confirmation) {
      setSwitchLoading(true);
      const { isCryptoBirdActivated } = user;

      const userUpdate = {
        isCryptoBirdActivated: !isCryptoBirdActivated,
        userId: user._id,
      };

      const { data } = await switchCryptoBird(userUpdate);

      const { data: afterUpdate } = await getUser();
      const { user: userUpdateData } = afterUpdate;
      setUser(userUpdateData);

      if (data.success) {
        console.log("CONFIRMED");
        setIsCryptoBirdActivated(!isCryptoBirdActivated);
        setSwitchLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      setIsloading(true);
      const { data } = await getUser();
      const { user, success, error } = data;

      if (success) {
        console.log("user", user);
        setUser(user);
        setIsCryptoBirdActivated(user.isCryptoBirdActivated);

        const avg = calculateAverageLossPercentage(
          user.demoPortfolio,
          user.demoPortfolioBudget.singleInvestmentLimit *
            user.demoPortfolioBudget.leverage
        );

        setAvgRisk(avg);
      }

      setIsloading(false);
    };
    fetchUser();
  }, []);

  const advancedUser =
    user.tradingExperience !== "Beginner" &&
    user.tradingExperience !== "Intermediate";

  const refreshUser = async () => {
    try {
      const { data } = await getUser();
      const { user, success } = data;

      if (success) {
        setUser(user);
      }
    } catch (error) {
      console.error("Error refreshing user:", error);
    }
  };

  const handleSaveChanges = async () => {
    // Save changes to the backend

    alert("I am aware of the risks and I want to save the changes.");

    const updatedUserRiskManagement = {
      demoPortfolioBudget: user.demoPortfolioBudget,
      demoPortfolio: user.demoPortfolio,
      userId: user._id,
    };

    const { data } = await putUser(updatedUserRiskManagement);

    if (data.success) {
      setIsEditing(false);
    }
  };

  const handleCancelSave = async () => {
    const { data } = await getUser();
    const { user } = data;
    setUser(user);
    setIsEditing(false);
  };

  const onRiskManagementChange = (e) => {
    setIsEditing(true);
    const { name, value } = e.target;
    const updatedUser = { ...user };
    updatedUser.demoPortfolioBudget[name] = value;
    setUser(updatedUser);

    const avg = calculateAverageLossPercentage(
      user.demoPortfolio,
      user.demoPortfolioBudget.singleInvestmentLimit *
        user.demoPortfolioBudget.leverage
    );

    setAvgRisk(avg);
  };

  const calculateAllocatedFunds = (demoPortfolioBudget) => {
    const {
      aiTokens,
      gamingTokens,
      fundingTokens,
      surfingTokens,
      trendingTokens,
      performingTokens,
    } = demoPortfolioBudget;

    const currentAllocation = (
      Number(aiTokens) +
      Number(gamingTokens) +
      Number(fundingTokens) +
      Number(surfingTokens) +
      Number(trendingTokens) +
      Number(performingTokens) -
      currentStrategyTokensValue("trendingTokens") -
      currentStrategyTokensValue("performingTokens") -
      currentStrategyTokensValue("surfingTokens") -
      currentStrategyTokensValue("aiTokens") -
      currentStrategyTokensValue("gamingTokens") -
      currentStrategyTokensValue("fundingTokens")
    ).toFixed(0);

    return currentAllocation;
  };

  // Check current strategy budget usage
  const currentStrategyTokensValue = (strategyName) => {
    const accumulatedValue = user.demoPortfolio[strategyName].reduce(
      (acc, token) => {
        const { active, orderQuantity, leverage, value } = token;

        if (active) {
          return acc + (value * orderQuantity) / leverage;
        } else {
          return acc;
        }
      },
      0
    );

    const formattedValue = largeMoneyValueFormatter(
      accumulatedValue.toFixed(0)
    );

    return accumulatedValue.toFixed(0);
  };

  // const onFundAllocationChange = (e, name) => {
  //   setIsEditing(true);

  //   // const { name, value } = e.target;
  //   const value = e;
  //   const updatedUser = { ...user };

  //   const {
  //     aiTokens,
  //     gamingTokens,
  //     fundingTokens,
  //     surfingTokens,
  //     trendingTokens,
  //     performingTokens,
  //   } = updatedUser.demoPortfolioBudget;

  //   updatedUser.demoPortfolioBudget[name] = value;
  //   setUser(updatedUser);
  // };

  if (!isLoading) {
    return (
      <div className="container">
        <div className="row">
          <div className="d-flex col-12 mt-2">
            <h1 style={{ color: "grey" }}>
              Hello <span style={{ color: "#fff" }}>{user.firstName}</span>,
            </h1>
          </div>
        </div>
        <div className="row">
          <p style={{ color: "grey" }}>
            Use the switch to enable or disable CryptoBird
          </p>
          <div className="col-12" style={{ fontSize: "1.5rem", color: "#000" }}>
            <div
              style={{
                backgroundColor: isCryptoBirdActivated ? "#f8f9fa" : "#ffb3b3",
                borderRadius: "0.5rem",
                padding: "35px",
              }}
              // onClick={handleToggle}
            >
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={isCryptoBirdActivated}
                  onChange={handleToggle}
                  style={{
                    // Custom inline style to change switch color
                    backgroundColor: isCryptoBirdActivated
                      ? "#28a745"
                      : "#dc3545", // Green when activated, Red when deactivated
                    borderColor: isCryptoBirdActivated ? "#28a745" : "#dc3545", // Border color
                    cursor: "pointer",
                  }}
                />
                {!switchLoading && (
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    CryptoBird is {isCryptoBirdActivated ? "ON" : "OFF"}{" "}
                  </label>
                )}
                {switchLoading && (
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    <img src={Spinner1} height="30px" />
                  </label>
                )}
              </div>
            </div>
          </div>
        </div>
        <h1 style={{ color: "grey", marginTop: "20px", marginBottom: "20px" }}>
          Your <span style={{ color: "#fff" }}>Risk Management</span>:
        </h1>
        <div
          className="risk-management"
          style={{ marginTop: "10px", borderRadius: "10px" }}
        >
          <div className="row">
            <div className="col-12 col-sm-12 ">
              <h4 className="mt-3" style={{ margin: "10px", color: "#fff" }}>
                Single Investment
                <span
                  style={{
                    color: "grey",
                    marginLeft: "8px",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    onClick={handleShowSingleInvestmentInfo}
                  />
                </span>
              </h4>
              {singleIvestmentInfo && (
                <div className="container">
                  <InfoTile
                    keyword="SINGLE INVESTMENT"
                    onClose={handleCloseInfo}
                  />
                </div>
              )}
              {isEditing && (
                <div className="form-group m-3 align-items-center fade-in">
                  <div className="d-flex btn-group">
                    <button
                      className="btn btn-success"
                      onClick={handleSaveChanges}
                    >
                      SAVE
                    </button>

                    <button
                      className="btn btn-secondary"
                      onClick={handleCancelSave}
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
              )}

              <div
                style={{
                  background: "#000",
                  borderRadius: "0.5rem",
                  padding: "10px",
                  margin: "10px",
                  height: "120px",
                }}
              >
                <div className="d-flex justify-content-center">
                  <div style={{ color: "lightgrey", fontSize: "4rem" }}>
                    $
                    {largeMoneyValueFormatter(
                      user.demoPortfolioBudget.singleInvestmentLimit *
                        user.demoPortfolioBudget.leverage
                    )}
                  </div>
                </div>
              </div>

              {advancedUser && (
                <div className=" mt-1">
                  <div
                    style={{
                      background: "#000",
                      borderRadius: "0.5rem",
                      padding: "10px",
                      margin: "10px",
                      height: "70px",
                    }}
                  >
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="d-flex">
                        <div className="d-flex align-items-center">
                          <div style={{ color: "grey", marginLeft: "10px" }}>
                            LEVERAGE{" "}
                          </div>{" "}
                          <div>
                            <select
                              value={user.demoPortfolioBudget.leverage}
                              className="form-control dark-background width50"
                              name="leverage"
                              onChange={onRiskManagementChange}
                              style={{
                                backgroundColor: "transparent",
                                borderRadius: "0.5rem",
                                padding: "0.5rem",
                                color: "grey",
                                cursor: "pointer",

                                border: "1px solid #333",
                              }}
                              required
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                            </select>
                          </div>
                          <span
                            style={{
                              color: "#333",
                              marginLeft: "5px",
                              cursor: "pointer",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCircleInfo}
                              onClick={handleShowLeverageInfo}
                            />
                          </span>
                        </div>
                      </div>
                      {/* <div
                        style={{
                          color: "#ff8080",
                          fontSize: "1rem",
                          marginLeft: "10px",
                        }}
                      >
                        <span style={{ color: "grey" }}>Avg Risk: </span>
                        <span
                          style={{
                            color: "grey",
                            marginLeft: "5px",
                            marginRight: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            onClick={handleShowAverageRiskInfo}
                          />
                        </span>
                        <span>${Math.abs(avgRisk.toFixed(2))}</span>
                      </div> */}
                    </div>
                  </div>
                  {averageRiskInfo && (
                    <div className="container">
                      <InfoTile keyword="Avg Risk" onClose={handleCloseInfo} />
                    </div>
                  )}
                  {leverageInfo && (
                    <div className="container">
                      <InfoTile keyword="LEVERAGE" onClose={handleCloseInfo} />
                    </div>
                  )}
                </div>
              )}
              <div className="col-12 col-sm-6 mt-3">
                <div
                  className="col-12 "
                  style={{ fontSize: "1.2rem", color: "grey" }}
                >
                  <h4 style={{ paddingLeft: "10px", color: "#fff" }}>
                    Budget Allocation
                    <span
                      style={{
                        color: "grey",
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        onClick={handleShowBudgetAllocationInfo}
                      />
                    </span>
                  </h4>
                  {budgetAllocationInfo && (
                    <div className="container">
                      <InfoTile
                        keyword="BUDGET ALLOCATION"
                        onClose={handleCloseInfo}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      background: "#000",
                      borderRadius: "0.5rem",
                      margin: "10px",
                      padding: "10px",
                      height: "290px",
                    }}
                  >
                    <div className="d-flex align-items-center mt-3 ">
                      <div
                        style={{
                          color: "grey",
                          fontSize: "1rem",
                        }}
                      >
                        Performing
                      </div>
                      <div style={{ marginLeft: "5px", color: "lightgreen" }}>
                        $
                        {largeMoneyValueFormatter(
                          parseFloat(
                            user.demoPortfolioBudget.performingTokens
                          ).toFixed(0)
                        )}
                      </div>
                      {currentStrategyTokensValue("performingTokens") > 0 && (
                        <div style={{ color: "grey", marginLeft: "5px" }}>
                          <span
                            style={{
                              marginLeft: "10px",
                              marginRight: "5px",
                              color: "grey",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faLock}
                              style={{
                                fontSize: "12px",
                                position: "relative",
                                top: "-0.2rem",
                                color: "orange",
                              }}
                            />
                          </span>
                          <span style={{ color: "orange" }}>
                            ${currentStrategyTokensValue("performingTokens")}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center mt-3">
                      <div
                        style={{
                          color: "#fff",
                          fontSize: "1rem",
                        }}
                      >
                        <span
                          style={{
                            color: "grey",
                            fontSize: "1rem",
                          }}
                        >
                          Trending
                        </span>
                      </div>
                      <div style={{ marginLeft: "5px", color: "lightgreen" }}>
                        $
                        {largeMoneyValueFormatter(
                          parseFloat(
                            user.demoPortfolioBudget.trendingTokens
                          ).toFixed(0)
                        )}
                      </div>
                      {currentStrategyTokensValue("trendingTokens") > 0 && (
                        <div style={{ color: "grey", marginLeft: "15px" }}>
                          <span
                            style={{ marginLeft: "10px", marginRight: "5px" }}
                          >
                            <FontAwesomeIcon
                              icon={faLock}
                              style={{
                                fontSize: "12px",
                                position: "relative",
                                top: "-0.2rem",
                                color: "orange",
                              }}
                            />
                          </span>
                          <span style={{ color: "orange" }}>
                            ${currentStrategyTokensValue("trendingTokens")}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="d-flex align-items-center mt-3">
                      <div
                        style={{
                          color: "grey",
                          fontSize: "1rem",
                        }}
                      >
                        Surfing
                      </div>
                      <div style={{ marginLeft: "5px", color: "lightgreen" }}>
                        $
                        {largeMoneyValueFormatter(
                          parseFloat(
                            user.demoPortfolioBudget.surfingTokens
                          ).toFixed(0)
                        )}
                      </div>
                      {currentStrategyTokensValue("surfingTokens") > 0 && (
                        <div style={{ color: "grey" }}>
                          <span
                            style={{ marginLeft: "15px", marginRight: "5px" }}
                          >
                            <FontAwesomeIcon
                              icon={faLock}
                              style={{
                                fontSize: "12px",
                                position: "relative",
                                top: "-0.2rem",
                                color: "orange",
                              }}
                            />
                          </span>
                          <span style={{ color: "orange" }}>
                            ${currentStrategyTokensValue("surfingTokens")}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="d-flex align-items-center mt-3">
                      <div
                        style={{
                          color: "grey",
                          fontSize: "1rem",
                        }}
                      >
                        Rebalancer
                      </div>
                      <div style={{ marginLeft: "5px", color: "lightgreen" }}>
                        $
                        {largeMoneyValueFormatter(
                          parseFloat(
                            user.demoPortfolioBudget.fundingTokens
                          ).toFixed(0)
                        )}
                      </div>
                      {currentStrategyTokensValue("fundingTokens") > 0 && (
                        <div style={{ color: "grey" }}>
                          <span
                            style={{ marginLeft: "15px", marginRight: "5px" }}
                          >
                            <FontAwesomeIcon
                              icon={faLock}
                              style={{
                                fontSize: "12px",
                                position: "relative",
                                top: "-0.2rem",
                                color: "orange",
                              }}
                            />
                          </span>
                          <span style={{ color: "orange" }}>
                            ${currentStrategyTokensValue("fundingTokens")}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="mt-2">
                      <div
                        style={{
                          color: "grey",
                          fontSize: "1.2rem",
                          padding: "10px",
                          border: "1px solid #333",
                          borderRadius: "10px",
                        }}
                      >
                        Available
                        <span
                          style={{
                            fontSize: "2rem",
                            color: "lightgreen",
                            marginLeft: "10px",
                          }}
                        >
                          $
                          {largeMoneyValueFormatter(
                            calculateAllocatedFunds(user.demoPortfolioBudget)
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-4">
                  <h4
                    className="mt-3"
                    style={{ margin: "10px", color: "#fff" }}
                  >
                    Rebalancer
                    <span
                      style={{
                        color: "grey",
                        marginLeft: "8px",
                        cursor: "pointer",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        onClick={handleShowRebalancerInfo}
                      />
                    </span>
                  </h4>
                  {rebalancerInfo && (
                    <div className="container">
                      <InfoTile
                        keyword="REBALANCING"
                        onClose={handleCloseInfo}
                      />
                    </div>
                  )}
                  <Portfolio
                    user={user}
                    title="REBALANCING"
                    strategy="fundingTokens"
                    refreshUser={refreshUser}
                  />
                </div>
              </div>

              {/* <h4
                className="mt-1"
                style={{ paddingLeft: "10px", color: "#fff" }}
              >
                Exchanges
                <span
                  style={{
                    color: "grey",
                    marginLeft: "8px",
                    cursor: "pointer",
                  }}
                >
                  <FontAwesomeIcon icon={faCircleInfo} />
                </span>
              </h4>
              <div
                style={{
                  background: "#000",
                  borderRadius: "0.5rem",
                  // padding: "10px",
                  height: "185px",
                  display: "flex",
                  justifyContent: "space-evenly",
                  margin: "10px",
                }}
              >
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    src={Binance}
                    alt="binance"
                    style={{ width: "70px", height: "70px", margin: "0 10px" }}
                  />
                  <img
                    src={Kucoin}
                    alt="kucoin"
                    style={{ width: "70px", height: "70px", margin: "0 10px" }}
                  />
                  <img
                    src={Lcx}
                    alt="kucoin"
                    style={{ width: "70px", height: "70px", margin: "0 10px" }}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <h1 style={{ color: "grey", marginTop: "20px", marginBottom: "20px" }}>
          <span style={{ color: "#fff" }}>Account</span> Settings:
        </h1>
        <div
          className="credentials"
          style={{ marginTop: "10px", borderRadius: "15px" }}
        >
          <div className="row">
            <div
              className="col-12 col-sm-6 mt-3"
              style={{ fontSize: "1.2rem", color: "#fff" }}
            >
              <h4 className="mt-1" style={{ margin: "10px", color: "#fff" }}>
                User Profile
                <span
                  style={{
                    color: "grey",
                    marginLeft: "8px",
                    cursor: "pointer",
                  }}
                ></span>
              </h4>
              <div
                style={{
                  background: "#000",
                  borderRadius: "0.5rem",
                  margin: "10px",
                  padding: "10px",
                }}
              >
                <div>
                  <span style={{ color: "grey" }}>Username:</span>{" "}
                  {user.username}
                </div>
                <div>
                  <span style={{ color: "grey" }}>Email:</span> {user.email}
                </div>
                <div>
                  <span style={{ color: "grey" }}>Password:</span> ********{" "}
                  <span
                    style={{
                      marginLeft: "3px",
                      color: "grey",
                      cursor: "pointer",
                    }}
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                  </span>
                </div>
                <div>
                  <span style={{ color: "grey" }}>Subscription:</span>{" "}
                  <span className="badge bg-warning text-dark">DEMO</span>
                </div>
              </div>
            </div>

            {!user.demoAccount && (
              <div>
                <div className="row">
                  <div className="d-flex col-12 mt-5">
                    <h1 style={{ color: "grey" }}>CEX API Keys</h1>
                  </div>
                </div>

                <div
                  className=" col-12 mt-2"
                  style={{ fontSize: "1.2rem", color: "#fff" }}
                >
                  <div
                    style={{
                      background: "#292e34",
                      borderRadius: "0.5rem",
                      padding: "15px",
                    }}
                  >
                    <h4>
                      Exchange API KEYS
                      <span
                        style={{
                          color: "#fff",
                          marginLeft: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon icon={faCircleInfo} />
                      </span>
                    </h4>

                    <div>
                      <span style={{ color: "grey" }}>BINANCE API KEY:</span>{" "}
                      ***********
                      <span
                        style={{
                          marginLeft: "3px",
                          color: "grey",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </span>
                    </div>
                    <div>
                      <span style={{ color: "grey" }}>BINANCE SECRET KEY:</span>{" "}
                      ***********
                      <span
                        style={{
                          marginLeft: "3px",
                          color: "grey",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </span>
                    </div>
                    <div>
                      <span style={{ color: "grey" }}>KUCOIN API KEY:</span>{" "}
                      ***********
                      <span
                        style={{
                          marginLeft: "3px",
                          color: "grey",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </span>
                    </div>
                    <div>
                      <span style={{ color: "grey" }}>KUCOIN SECRET KEY:</span>{" "}
                      ***********
                      <span
                        style={{
                          marginLeft: "3px",
                          color: "grey",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>{" "}
        <h1 style={{ color: "grey", marginTop: "20px", marginBottom: "10px" }}>
          <span style={{ color: "#fff" }}>Danger</span> Zone:
        </h1>
        <div
          className="danger-zone"
          style={{ borderRadius: "15px", padding: "15px" }}
        >
          DELETE DEMO ACCOUNT
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="container mt-3 d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <img src={Spinner} alt="spinner" height="400px" />
      </div>
    );
  }
};

export default UserSettings;
