import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import "./dashboard.css";
import { getMarketSentiment } from "../../utils/dataService";
import Statistics from "./Statistics";
import Orders from "./Orders";
import Menu from "./Menu";
import BinanceAccount from "./BinanceAccount";
import KucoinAccount from "./KucoinAccount";
import UserSettings from "./UserSettings";
import Welcome from "./Welcome";
import Spinner from "../../img/spinner.gif";
import Prospects from "./Prospects";
import Footer from "../elements/Footer";
import Faq from "./Faq";

const DemoDashboard = ({
  username,
  trial,
  userId,
  vault,
  demoLeverage,
  isCryptoBirdActivated,
  firstName,
}) => {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [sentiments, setSentiments] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [url, setUrl] = useState("orders");

  useEffect(() => {
    const updateSentiments = async () => {
      const { data: sentimets } = await getMarketSentiment();
      setSentiments(sentimets.sentiments);
    };

    setShowResults(true);
    updateSentiments();
  }, [cookies, navigate, removeCookie]);

  const crdbToken = localStorage.getItem("crbd_token");

  if (!crdbToken) {
    navigate("/login");
    return null;
  }

  return (
    <div className="container-fluid">
      <div style={{ marginBottom: "120px" }}>
        <Menu
          setUrl={setUrl}
          firstName={firstName}
          username={username}
          trial={trial}
          userId={userId}
          isCryptoBirdActivated={isCryptoBirdActivated}
        />
      </div>

      {!showResults && url === "orders" && (
        <div
          className="container mt-3 d-flex justify-content-center align-items-center"
          style={{ height: "80vh" }}
        >
          <img src={Spinner} alt="spinner" />
        </div>
      )}
      {showResults && url === "statistics" && <Statistics />}
      {showResults && url === "orders" && (
        <Orders
          sentiments={sentiments}
          userId={userId}
          vault={vault}
          trial={trial}
          demoLeverage={demoLeverage}
          setUrl={setUrl}
          firstName={firstName}
        />
      )}
      {showResults && url === "prospects" && <Prospects />}
      {showResults && url === "binanceaccount" && (
        <BinanceAccount userId={userId} />
      )}
      {showResults && url === "kucoinaccount" && (
        <KucoinAccount userId={userId} />
      )}
      {showResults && url === "usersettings" && (
        <UserSettings userId={userId} />
      )}
      {showResults && url === "welcome" && <Welcome />}
      {showResults && url === "faq" && <Faq />}
      <Footer />
    </div>
  );
};

export default DemoDashboard;
