import React from "react";
import MetamaskLogo from "../../img/metamasklogo1.png";

const Portfolio360 = () => {
  return (
    <div className="contaier d-flex justify-content-center flex-column">
      <div className="container d-flex justify-content-center align-items-center">
        <img src={MetamaskLogo} alt="spinner" style={{ height: "500px", marginTop: "40px" }} />
      </div>
      <div style={{ color: "lightgrey", fontSize: "4rem", textAlign: "center", marginTop: "20px" }}>
        <button className="btn btn-outline-light btn-lg">Connect Wallet</button>
      </div>
    </div>
  );
};

export default Portfolio360;
