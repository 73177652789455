import moment from "moment";

export function formatBinanceWalletResponse({ data }) {
  const tokens = Object.keys(data);
  const formattedTokens = [];
  const totalusdValue = tokens.reduce((acc, token) => {
    const { usdValue } = data[token];
    if (token === "USDT") return acc + Number(data[token].available);
    return acc + Number(usdValue);
  }, 0);

  for (const token of tokens) {
    const { available, usdValue, onOrder, total } = data[token];

    const tokenData = {
      name: token,
      available: available,
      total,
      onOrder,
      usdValue:
        token === "USDT"
          ? Number(available).toFixed(2)
          : Number(usdValue).toFixed(2),
    };

    formattedTokens.push(tokenData);
  }

  return { formattedTokens, totalusdValue };
}

export function convertToHumanReadable(number) {
  // Convert the number to a string
  const numberStr = number.toString();

  // Use a regular expression to check if it matches scientific notation
  // The pattern matches numbers like "aEb" or "aE+b" or "aE-b"
  const scientificNotationPattern = /^[+-]?(\d+(\.\d*)?|\.\d+)([eE][+-]?\d+)$/;

  if (scientificNotationPattern.test(numberStr)) {
    // If it's in scientific notation, convert it to a human-readable format
    return Number(number).toFixed(7);
  } else {
    // If it's not in scientific notation, return the number as-is
    return number;
  }
}

export function calculateAveragePrice(order) {
  const numberOfPurchases = order.purchases.length + 1; // purchases + initial order

  let initialPrice = order.value;

  for (const purchase of order.purchases) {
    const { price } = purchase;
    initialPrice += price;
  }

  return (initialPrice / numberOfPurchases).toFixed(2);
}

export function formatKucoinWalletResponse(liabilities, tickers) {
  const kucoinTokens = [];
  const { ticker } = tickers;

  liabilities.map(
    async ({
      currency,
      totalBalance: balance,
      liability,
      availableBalance: available,
    }) => {
      const formattedToken = {
        name: currency,
        available: available,
        total: balance,
        liability:
          currency === "USDT"
            ? Number(liability).toFixed(2)
            : (
                liability *
                ticker.find((token) => token.symbol === `${currency}-USDT`)
                  ?.last
              ).toFixed(2),
        usdValue:
          currency !== "USDT"
            ? (
                balance *
                ticker.find((token) => token.symbol === `${currency}-USDT`)
                  ?.last
              ).toFixed(2)
            : Number(balance).toFixed(2),
      };

      kucoinTokens.push(formattedToken);
    }
  );

  // const filterLessThanOne = kucoinTokens.filter((token) => token.usdValue > 1);
  // const zeroBalance = kucoinTokens.filter((token) => token.available < 1);

  return { kucoinTokens };
}

export function getLastEightWeekDates() {
  const last8Mondays = [];
  let currentDate = moment().endOf("week");

  for (let i = 0; i < 8; i++) {
    last8Mondays.push(currentDate.format("YYYY-MM-DD"));
    currentDate = currentDate.subtract(7, "days");
  }

  return last8Mondays;
}

export function getLastSixMonthsLastDays() {
  const today = moment(); // Get the current date
  const lastSixMonthsLastDays = [];

  for (let i = 0; i < 3; i++) {
    // Include the current month and previous six months
    const monthInfo = {
      name: today.format("MMMM"),
      lastDay: today.endOf("month").format("YYYY-MM-DD"),
    };
    lastSixMonthsLastDays.push(monthInfo);
    today.subtract(1, "months"); // Move to the previous month
  }

  return lastSixMonthsLastDays;
}

export function calculateDailyStatistics(data) {
  let totalOrders, losses, secured, profits;

  if (!data) return { totalOrders: 0, losses: 0, secured: 0, profits: 0 };

  totalOrders = data.length;
  losses = data.filter(({ profit }) => profit === false).length;
  profits = data.filter(({ isLongProfit }) => isLongProfit === true).length;
  secured = data.filter(
    ({ isShortProfit, isLongProfit }) => isShortProfit === true && !isLongProfit
  ).length;

  return { totalOrders, losses, secured, profits };
}

export function getLast7Days() {
  const today = new Date();
  const last7Days = [];

  for (let i = 0; i < 7; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    last7Days.push(formattedDate);
  }

  return last7Days;
}

export function getLast7DaysFromDate(inputDate) {
  const result = [];
  const currentDate = new Date();
  const targetDate = new Date(inputDate);

  const endDate = targetDate > currentDate ? currentDate : targetDate;

  for (let i = 6; i >= 0; i--) {
    const currentDateCopy = new Date(endDate);
    currentDateCopy.setDate(endDate.getDate() - i);

    const year = currentDateCopy.getFullYear();
    const month = String(currentDateCopy.getMonth() + 1).padStart(2, "0");
    const day = String(currentDateCopy.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    result.push(formattedDate);
  }

  return result.reverse();
}

// export function getMondaysOfMonth(todayDate) {
//   const result = [];
//   const targetDate = new Date(todayDate);
//   const year = targetDate.getFullYear();
//   const month = targetDate.getMonth();
//   const today = new Date();

//   // Find the first day of the month
//   const firstDayOfMonth = new Date(year, month, 1);
//   console.log("firstDayOfMonth", firstDayOfMonth);
//   // Find the Monday of the week where the first day of the month falls
//   const firstMonday = new Date(firstDayOfMonth);
//   firstMonday.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay() + 1);
//   console.log("firstMonday", firstMonday);

//   // Check if the first Monday falls before the start of the month
//   if (firstMonday.getMonth() !== month) {
//     firstMonday.setDate(firstMonday.getDate() + 7);
//   }

//   // Add the first Monday into the result array
//   result.push(firstMonday.toISOString().slice(0, 10));

//   // Find the Mondays of the following weeks in the month
//   let nextMonday = new Date(firstMonday);
//   while (nextMonday.getMonth() === month && nextMonday <= today) {
//     nextMonday.setDate(nextMonday.getDate() + 7);
//     if (nextMonday <= today) {
//       result.push(nextMonday.toISOString().slice(0, 10));
//     }
//   }

//   return result;
// }
export function getMondaysOfMonth(lastDayOfMonth) {
  const result = [];
  const targetDate = new Date(lastDayOfMonth);
  const year = targetDate.getFullYear();
  const month = targetDate.getMonth();

  // Find the first day of the month
  const firstDayOfMonth = new Date(year, month, 1);

  // Find the Monday of the week where the first day of the month falls
  const firstMonday = new Date(firstDayOfMonth);
  firstMonday.setDate(firstDayOfMonth.getDate() - firstDayOfMonth.getDay() + 1);

  // If the first Monday is not in the current month, add the first day of the month to the results
  if (firstMonday.getMonth() !== month) {
    result.push(firstDayOfMonth.toISOString().slice(0, 10));

    firstMonday.setDate(firstMonday.getDate() + 7); // Move to the first Monday in the current month
  }

  // Add the first Monday into the result array
  result.push(firstMonday.toISOString().slice(0, 10));

  // Find the Mondays of the following weeks in the month
  let nextMonday = new Date(firstMonday);
  while (nextMonday.getMonth() === month && nextMonday <= targetDate) {
    nextMonday.setDate(nextMonday.getDate() + 7);
    if (nextMonday.getMonth() === month && nextMonday <= targetDate) {
      result.push(nextMonday.toISOString().slice(0, 10));
    }
  }

  return result;
}

export function getWeekFromDate(date) {
  const result = [];
  const currentDate = new Date(date);

  // Iterate to add next 6 days to the array
  for (let i = 0; i < 7; i++) {
    const day = new Date(currentDate);
    day.setDate(currentDate.getDate() + i);
    result.push(day.toISOString().slice(0, 10));
  }

  return result;
}

export function formatValue(value) {
  value = Math.abs(value); // Take absolute value

  if (value < 10000) {
    return "$" + value;
  } else if (value < 1000000) {
    const formattedValue = (value / 1000).toFixed(1);
    return (
      "$" +
      (formattedValue.endsWith(".0")
        ? formattedValue.slice(0, -2)
        : formattedValue) +
      "K"
    );
  } else {
    const formattedValue = (value / 1000000).toFixed(1);
    return (
      "$" +
      (formattedValue.endsWith(".0")
        ? formattedValue.slice(0, -2)
        : formattedValue) +
      "M"
    );
  }
}

export function largeMoneyValueFormatter(number) {
  // Convert the number to a string
  let numStr = String(Math.abs(number));

  // Split the number into integer and decimal parts
  let parts = numStr.split(".");

  // Format the integer part with commas
  let formattedInteger = "";
  let integerPart = parts[0];
  let count = 0;

  // Iterate through each character in the integer part, starting from the end
  for (let i = integerPart.length - 1; i >= 0; i--) {
    // Add the current character to the formatted integer part
    formattedInteger = integerPart[i] + formattedInteger;

    // Increment the counter
    count++;

    // If the counter is a multiple of 3 and it's not the first digit
    if (count % 3 === 0 && i !== 0) {
      // Add a comma to separate groups of 3 digits
      formattedInteger = "," + formattedInteger;
    }
  }

  // Combine the integer part and decimal part
  let formattedNum = formattedInteger;
  if (parts.length > 1) {
    formattedNum += "." + parts[1];
  }

  return formattedNum;
}
