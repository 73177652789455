import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const FundingTokenChart = ({ balances }) => {
  let data = [];

  if (balances) {
    data = balances.map((balance) => {
      const { date, token } = balance;
      const { qty, vault, usdtValue, price, compareQty } = token;

      const value = qty === 0 ? 0 : qty * price;
      const hodl = compareQty * price;

      return {
        date,
        balanced: value.toFixed(0),
        hodl: hodl.toFixed(0),
      };
    });
  }

  return (
    <div style={{ width: "100%", height: 500 }}>
      <ResponsiveContainer>
        <AreaChart
          width={800}
          height={500}
          data={data}
          margin={{
            top: 20,
            right: 0,
            left: 0,
            bottom: 10,
          }}
        >
          <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="rgba(33, 150, 243, 0.56)" />
              <stop offset="100%" stopColor="rgba(33, 150, 243, 0.04)" />
            </linearGradient>
            <linearGradient
              id="potentialColorGradient"
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="0%" stopColor="rgba(255, 165, 0, 0.56)" />
              <stop offset="100%" stopColor="rgba(255, 165, 0, 0.04)" />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="4 4 " stroke="#333" />
          <XAxis dataKey="date" display="none" />
          <YAxis orientation="right" axisLine={false} />
          <Tooltip
            viewBox={{ x: 0, y: 0, width: 300, height: 400 }}
            wrapperStyle={{ color: "#000", opacity: 0.9, outline: "none" }}
            itemStyle={{ color: "#000" }}
          />
          <Legend verticalAlign="bottom" height={36} />
          {/* Area for value */}
          <Area
            type="monotone"
            dataKey="balanced"
            stroke="rgba(33, 150, 243, 1)"
            fill="url(#colorGradient)"
          />
          {/* Area for hodl */}
          <Area
            type="monotone"
            dataKey="hodl"
            stroke="rgba(255, 165, 0, 1)"
            fill="url(#potentialColorGradient)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FundingTokenChart;
