import React, { useState, useEffect } from "react";
import Spinner from "../../img/spinner.gif";
import { getPropsects, getTrendingProspects, getSurfingProspects } from "../../utils/dataService";
import TileComponent from "../elements/Tiles";

const Prospects = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [prospects, setProspects] = useState([]);
  const [trendingProspects, setTrendingProspects] = useState([]);
  const [surfingProspects, setSurfingProspects] = useState([]);

  useEffect(() => {
    const fetchPropsects = async () => {
      const { data: prospects } = await getPropsects();
      const { data: trendingProspects } = await getTrendingProspects();
      const { data: surfingProspects } = await getSurfingProspects();

      setProspects(prospects);
      setTrendingProspects(trendingProspects);
      setSurfingProspects(surfingProspects);
      setIsLoading(false);
    };

    fetchPropsects();
  }, []);

  if (isLoading) {
    return (
      <div className="container mt-3 d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
        <img src={Spinner} alt="spinner" />
      </div>
    );
  }

  return (
    <div className="container mt-3">
      <div className="mt-5">
        <TileComponent
          prospects={prospects}
          trendingProspects={trendingProspects}
          surfingProspects={surfingProspects}
        />
      </div>
    </div>
  );
};

export default Prospects;
