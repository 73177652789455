import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBitcoin } from "@fortawesome/free-brands-svg-icons";
import { faChartSimple, faCloud, faArrowTrendUp, faArrowTrendDown, faClock } from "@fortawesome/free-solid-svg-icons";
import "./Tiles.css";

const TileComponent = ({ prospects, trendingProspects, surfingProspects }) => {
  const shortProspects = prospects.prospects.filter((token) => token.strategy === "short");
  const longProspects = prospects.prospects.filter((token) => token.strategy === "long");

  const determineMarketColor = (btcSentiment) => {
    const { btcMarketSentiment } = btcSentiment[0];

    switch (btcMarketSentiment) {
      case "bullish":
        return "lightgreen";
      case "neutral":
        return "lightgrey";
      case "bearish":
        return "#FF8080";
      default:
        return "grey";
    }
  };

  const determinePriceColor = (btcSentiment) => {
    const { btcPriceSentiment } = btcSentiment[0];

    switch (btcPriceSentiment) {
      case "bullish":
        return "lightgreen";
      case "neutral":
        return "lightgrey";
      case "bearish":
        return "#FF8080";
      default:
        return "lightgrey";
    }
  };

  const renderArrow = (market) => {
    if (market === "bullish") {
      return <FontAwesomeIcon icon={faArrowTrendUp} style={{ marginLeft: "12px", color: "lightgreen" }} size="1x" />;
    } else if (market === "bearish") {
      return <FontAwesomeIcon icon={faArrowTrendDown} style={{ marginLeft: "12px", color: "#FF8080" }} size="1x" />;
    } else {
      return <FontAwesomeIcon icon={faCloud} style={{ marginLeft: "12px", color: "grey" }} size="1x" />;
    }
  };

  return (
    <div className="container">
      {" "}
      <div className="mt-3">
        <h3 className="mt-3">
          Long <span style={{ marginLeft: "5px", color: "grey" }}>{longProspects.length}</span>
        </h3>
        {longProspects.length === 0 && (
          <div className="badge text-dark" style={{ background: "lightgrey" }}>
            No assets
          </div>
        )}
      </div>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">
        {longProspects.map((token) => (
          <div className="col mb-4" key={token.name}>
            <div className="card bg-dark">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div>
                    <h2 className="card-title">{token.name.split("USDT")[0]}</h2>
                  </div>
                  <div>
                    <span className="badge badge bg-success text-light mb-2 ms-2">{token.strategy.toUpperCase()}</span>
                  </div>
                  {token?.marginAllowed && (
                    <div>
                      <span
                        className={`badge badge bg-warning mb-2 ms-2`}
                        style={{ fontSize: "0.8rem", fontWeight: "900", color: "#000" }}
                      >
                        x{process.env.REACT_APP_INVESTMENT_LEVERAGE}
                      </span>
                    </div>
                  )}
                </div>
                <div>
                  <div className="d-flex mb-3">
                    <div className="text-light">
                      <FontAwesomeIcon
                        icon={faBitcoin}
                        size="lg"
                        style={{
                          color: determinePriceColor(token.btcSentiment),
                        }}
                      />
                    </div>
                    <div className="ms-2 text-light">
                      <FontAwesomeIcon
                        icon={faChartSimple}
                        size="lg"
                        style={{
                          color: determineMarketColor(token.btcSentiment),
                        }}
                      />
                    </div>
                    <div>{renderArrow(token.market)}</div>
                  </div>
                </div>
                <div>
                  {token.date.split(" ")[0]}
                  <FontAwesomeIcon icon={faClock} style={{ marginLeft: "15px", color: "grey" }} size="1x" />
                  <span style={{ color: "grey" }}> {token.date.split(" ")[1]}</span>
                </div>{" "}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-3">
        <h3>
          Short <span style={{ marginLeft: "5px", color: "grey" }}>{shortProspects.length}</span>
        </h3>
        {shortProspects.length === 0 && (
          <div className="badge text-dark" style={{ background: "lightgrey" }}>
            No assets
          </div>
        )}
      </div>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">
        {shortProspects.map((token) => (
          <div className="col mb-4" key={token.name}>
            <div className="card bg-dark">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div>
                    <h2 className="card-title">{token.name.split("USDT")[0]}</h2>
                  </div>
                  <div>
                    <span className="badge badge bg-danger text-light mb-2 ms-3">{token.strategy.toUpperCase()}</span>
                  </div>
                  {token?.marginAllowed && (
                    <div>
                      <span
                        className={`badge badge bg-warning mb-2 ms-2`}
                        style={{ fontSize: "0.8rem", fontWeight: "900", color: "#000" }}
                      >
                        x{process.env.REACT_APP_INVESTMENT_LEVERAGE}
                      </span>
                    </div>
                  )}
                </div>
                <div>
                  <div className="d-flex mb-3">
                    <div className="text-light">
                      <FontAwesomeIcon
                        icon={faBitcoin}
                        size="lg"
                        style={{
                          color: determinePriceColor(token.btcSentiment),
                        }}
                      />
                    </div>
                    <div className="ms-2 text-light">
                      <FontAwesomeIcon
                        icon={faChartSimple}
                        size="lg"
                        style={{
                          color: determineMarketColor(token.btcSentiment),
                        }}
                      />
                    </div>
                    <div>{renderArrow(token.market)}</div>
                  </div>
                </div>
                <div>
                  {token.date.split(" ")[0]}
                  <FontAwesomeIcon icon={faClock} style={{ marginLeft: "15px", color: "grey" }} size="1x" />
                  <span style={{ color: "grey" }}> {token.date.split(" ")[1]}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5">
        <h3 className="mt-3">
          Trending <span style={{ marginLeft: "5px", color: "grey" }}>{trendingProspects.prospects.length}</span>
        </h3>
        {trendingProspects.prospects.length === 0 && (
          <div className="badge text-dark" style={{ background: "lightgrey" }}>
            No assets
          </div>
        )}
      </div>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 bg-secondary rounded">
        {trendingProspects.prospects.map((token) => (
          <div className="col mb-3 mt-3" key={token.name}>
            <div className="card bg-dark">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div>
                    <h2 className="card-title">{token.name.split("USDT")[0]}</h2>
                  </div>
                  <div>
                    <span
                      className={`badge badge ${
                        token.strategy === "long" ? "bg-success" : "bg-danger"
                      } text-light mb-2 ms-3`}
                    >
                      {token.strategy.toUpperCase()}
                    </span>
                  </div>
                  {token?.marginAllowed && (
                    <div>
                      <span
                        className={`badge badge bg-warning mb-2 ms-2`}
                        style={{ fontSize: "0.8rem", fontWeight: "900", color: "#000" }}
                      >
                        x{process.env.REACT_APP_INVESTMENT_LEVERAGE}
                      </span>
                    </div>
                  )}
                </div>
                <div>
                  <div className="d-flex mb-3">
                    <div className="text-light">
                      <FontAwesomeIcon
                        icon={faBitcoin}
                        size="lg"
                        style={{
                          color: determinePriceColor(token.btcSentiment),
                        }}
                      />
                    </div>
                    <div className="ms-2 text-light">
                      <FontAwesomeIcon
                        icon={faChartSimple}
                        size="lg"
                        style={{
                          color: determineMarketColor(token.btcSentiment),
                        }}
                      />
                    </div>
                    <div>{renderArrow(token.market)}</div>
                  </div>
                </div>
                <div>
                  {token.date.split(" ")[0]}
                  <FontAwesomeIcon icon={faClock} style={{ marginLeft: "15px", color: "grey" }} size="1x" />
                  <span style={{ color: "grey" }}> {token.date.split(" ")[1]}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5">
        <h3 className="mt-3">
          Surfing <span style={{ marginLeft: "5px", color: "grey" }}>{surfingProspects.prospects.length}</span>
        </h3>
        {surfingProspects.prospects.length === 0 && (
          <div className="badge text-dark" style={{ background: "lightgrey" }}>
            No assets
          </div>
        )}
      </div>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 bg-secondary rounded">
        {surfingProspects.prospects.map((token) => (
          <div className="col mb-3 mt-3" key={token.name}>
            <div className="card bg-dark">
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <div>
                    <h2 className="card-title">{token.name.split("USDT")[0]}</h2>
                  </div>
                  <div>
                    <span
                      className={`badge badge ${
                        token.strategy === "long" ? "bg-success" : "bg-danger"
                      } text-light mb-2 ms-3`}
                    >
                      {token.strategy.toUpperCase()}
                    </span>
                  </div>
                  {token?.marginAllowed && (
                    <div>
                      <span
                        className={`badge badge bg-warning mb-2 ms-2`}
                        style={{ fontSize: "0.8rem", fontWeight: "900", color: "#000" }}
                      >
                        x{process.env.REACT_APP_INVESTMENT_LEVERAGE}
                      </span>
                    </div>
                  )}
                </div>
                <div>
                  <div className="d-flex mb-3">
                    <div className="text-light">
                      <FontAwesomeIcon
                        icon={faBitcoin}
                        size="lg"
                        style={{
                          color: determinePriceColor(token.btcSentiment),
                        }}
                      />
                    </div>
                    <div className="ms-2 text-light">
                      <FontAwesomeIcon
                        icon={faChartSimple}
                        size="lg"
                        style={{
                          color: determineMarketColor(token.btcSentiment),
                        }}
                      />
                    </div>
                    <div>{renderArrow(token.market)}</div>
                  </div>
                </div>
                <div>
                  {token.date.split(" ")[0]}
                  <FontAwesomeIcon icon={faClock} style={{ marginLeft: "15px", color: "grey" }} size="1x" />
                  <span style={{ color: "grey" }}> {token.date.split(" ")[1]}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TileComponent;
