import moment from "moment";

const singleInvestment = 25;

export function calculateInvestment(tokensNumer) {
  const investmentValue = tokensNumer * singleInvestment;
  return investmentValue;
}

export function calculatePercentageChange(currentValue, previousValue) {
  if (!previousValue || !currentValue) return 0;
  const percentageChange =
    ((currentValue - previousValue) / previousValue) * 100;
  return percentageChange.toFixed(3);
}

export function determinePercentageColor({ price, value, strategy }) {
  const change = calculatePercentageChange(price, value);

  if (change > 0) {
    return strategy === "long" ? "lightgreen" : "#FF8080";
  } else if (change < 0) {
    return strategy === "long" ? "#FF8080" : "lightgreen";
  }
}

export function calculatePercentageChangeTotal({ orders, price }) {
  let totalAvg = 0;
  let totalPnL = 0;

  for (const { value, name, type, orderQuantity } of orders) {
    const change = calculatePercentageChange(price[name], value);

    if (type === "short" && change < 0) {
      totalAvg += Math.abs(parseFloat(change));
      totalPnL += (Math.abs(parseFloat(change)) * value * orderQuantity) / 100;
    } else if (type === "long" && change > 0) {
      totalAvg += Math.abs(parseFloat(change));
      totalPnL += (Math.abs(parseFloat(change)) * value * orderQuantity) / 100;
    } else if (type === "long" && change < 0) {
      totalAvg -= Math.abs(parseFloat(change));
      totalPnL -= (Math.abs(parseFloat(change)) * value * orderQuantity) / 100;
    } else if (type === "short" && change > 0) {
      totalAvg -= Math.abs(parseFloat(change));
      totalPnL -= (Math.abs(parseFloat(change)) * value * orderQuantity) / 100;
    }
  }

  return totalPnL.toFixed(2);
}

export function calculateTotalFinances({
  userPortfolio,
  userVault,
  price,
  userInitialInvestment,
}) {
  let totalDebt = 0;
  let totalAssets = 0;
  let totalPnL = 0;

  const strategies = Object.keys(userPortfolio);

  for (let strategy of strategies) {
    const activeStrategy = userPortfolio[strategy].filter(
      (order) => order.active
    );

    for (const {
      borrowSize = 0,
      orderQuantity = 0,
      value = 0,
    } of activeStrategy) {
      totalDebt += borrowSize;
      totalAssets += value * orderQuantity;
    }

    totalPnL += Number(
      calculatePercentageChangeTotal({ orders: activeStrategy, price })
    );
  }

  const totalFinances = {
    totalAssets: Math.ceil(+totalAssets + userVault + totalPnL),
    debt: Math.ceil(+totalDebt),
    netAssets: Math.ceil(+userVault - +totalDebt + totalPnL),
    pnl: Math.ceil(totalPnL).toFixed(2),
    userInitialInvestment,
  };

  return totalFinances;
}

export function calculateSectorPercentageChange({ orders, price }) {
  let totalAvg = 0;
  let totalPnL = 0;

  for (const { value, name, orderQuantity } of orders) {
    const currentPrice = price[`${name}USDT`];

    const change = calculatePercentageChange(currentPrice, value);

    if (change > 0) {
      totalAvg += Math.abs(parseFloat(change));
      totalPnL += (Math.abs(parseFloat(change)) * value * orderQuantity) / 100;
    } else if (change < 0) {
      totalAvg -= Math.abs(parseFloat(change));
      totalPnL -= (Math.abs(parseFloat(change)) * value * orderQuantity) / 100;
    }
  }

  return totalPnL.toFixed(2);
}

export function calculateProfitLoss(orders) {
  if (orders.length > 0) {
    let totalValue = 0;

    for (const order of orders) {
      let currentValue = 0;
      const amount = (singleInvestment / order.value).toFixed(8);
      if (order.candles.length > 0) {
        currentValue = amount * order.candles[order.candles.length - 1].close;
      } else {
        currentValue = amount * order.value;
      }

      if (order.type === "short") {
        const initialValue = amount * order.value;
        const currentShortValue = initialValue + (initialValue - currentValue);
        totalValue += currentShortValue;
      } else {
        totalValue += currentValue;
      }
    }

    const profitLoss = totalValue;

    return profitLoss.toFixed(2);
  } else {
    return 0;
  }
}

export function calculateHours(orderDate) {
  const beginning = moment(orderDate);
  const ending = moment();
  const duration = ending.diff(beginning, "days");

  return duration;
}

export function calculateOrderDuration(token) {
  const { orderDate, closeTime = moment.utc().format("YYYY-MM-DD HH:mm") } =
    token;
  const beginning = moment(orderDate);
  const ending = moment(closeTime);

  const duration = ending.diff(beginning, "hours");

  return duration;
}

export function calculateAverages(tokens) {
  if (tokens.length === 0) return 0;
  let percentageSubChange = 0;
  let durationSubTotal = 0;
  const positivePercentageChanges = [];
  const negativePercentageChanges = [];
  let positivePercentageChange = 0;
  let negativePercentageChange = 0;
  let profitingOrders = 0;
  let losingOrders = 0;

  let change = 0;

  for (const token of tokens) {
    if (token.candles.length > 0) {
      change = calculatePercentageChange(
        token.candles[token.candles.length - 1].close,
        token.value
      );
    }

    if (change > 0) {
      if (token.type === "short") {
        percentageSubChange -= parseFloat(Math.abs(change));
      }

      positivePercentageChange += parseFloat(change);
      profitingOrders++;
      positivePercentageChanges.push(change);
    }

    if (change < 0) {
      if (token.type === "short") {
        percentageSubChange += parseFloat(Math.abs(change));
      }

      negativePercentageChange += parseFloat(change);
      losingOrders++;
      negativePercentageChanges.push(change);
    }

    const durationChangee = calculateOrderDuration(token);
    durationSubTotal += durationChangee;
  }

  const percentageChange =
    percentageSubChange !== 0
      ? (percentageSubChange / tokens.length).toFixed(2)
      : 0;

  const duration = Math.ceil(durationSubTotal / tokens.length);

  const calculatedWinRate =
    positivePercentageChange > 0
      ? (positivePercentageChange / profitingOrders).toFixed(2)
      : 0;

  const calculatedLoseRate =
    negativePercentageChange < 0
      ? (negativePercentageChange / losingOrders).toFixed(2)
      : 0;

  let maxChange = 0;

  if (positivePercentageChanges.length > 0) {
    maxChange = Math.max(...positivePercentageChanges);
  }

  let maxNegativeChange = 0;
  if (negativePercentageChanges.length > 0) {
    maxNegativeChange = Math.min(...negativePercentageChanges);
  }

  const averages = {
    duration,
    percentageChange,
    todaysPrice: 0,
    entry: 0,
    positivePercentageChange: calculatedWinRate,
    negativePercentageChange: calculatedLoseRate,
    maxChange,
    maxNegativeChange,
  };

  return averages;
}

export function calculateDollarProfitLoss(token) {
  if (token.candles.length === 0) return 0;
  const tokenAmount = singleInvestment / token.value;
  const orderOutcome = tokenAmount * token.closeValue - singleInvestment;
  return Math.abs(orderOutcome.toFixed(2));
}

export function calculateDollarProfitLossTotal(tokens) {
  if (tokens.length === 0) return 0;
  let total = 0;
  for (const token of tokens) {
    total += parseFloat(calculateDollarProfitLoss(token));
  }
  return total.toFixed(2);
}

export function calculateDollarProfitLossAverage(tokens) {
  if (tokens.length === 0) return 0;
  const total = calculateDollarProfitLossTotal(tokens);
  const average = total / tokens.length;
  return average.toFixed(2);
}

export function calculateOrdersAverageDuration(tokens) {
  if (tokens.length === 0) return 0;
  let total = 0;
  for (const token of tokens) {
    total += calculateOrderDuration(token);
  }
  const average = Math.ceil(total / tokens.length);
  return average;
}

export function calculateTotalAveragePercentageChange(tokens) {
  if (tokens.length === 0) return 0;
  let total = 0;
  for (const token of tokens) {
    total += +calculatePercentageChange(
      token.lastCandle.close,
      token.token.value
    );
  }
  const average = total / tokens.length;
  return average.toFixed(2);
}

export function calculateStatisticsTotalAveragePercentageChange(tokens) {
  if (tokens.length === 0) return 0;
  let total = 0;
  let negativeTotal = 0;
  let numberOfNegative = 0;
  let positiveTotal = 0;
  let numberOfPositive = 0;
  for (const token of tokens) {
    const change = calculatePercentageChange(token.closeValue, token.value);
    if (change < 3) {
      negativeTotal += +change;
      numberOfNegative++;
    } else {
      positiveTotal += +change;
      numberOfPositive++;
    }
    total += +change;
  }
  const average = (total / tokens.length).toFixed(2);
  const averageNegative =
    negativeTotal < 0 ? (negativeTotal / numberOfNegative).toFixed(2) : 0;
  const averagePositive =
    positiveTotal > 0 ? (positiveTotal / numberOfPositive).toFixed(2) : 0;

  const response = { average, averageNegative, averagePositive };
  return response;
}

export function calculateDaysDifference(startDate) {
  if (!startDate) return 0;
  const start = moment(startDate);
  const end = moment();
  const duration = end.diff(start, "days");
  return duration;
}

export function calculateAllocationPercentage(total, value) {
  const percentage = (value / total) * 100;
  return percentage.toFixed(2);
}

export function calculateAverageLoss(portfolio) {
  let totalLoss = 0;
  let totalTokens = 0;

  // Iterate through each token array in the portfolio object
  for (const key in portfolio) {
    if (Array.isArray(portfolio[key])) {
      portfolio[key].forEach((token) => {
        if (!token.active && !token.profit) {
          totalTokens++;
          let loss = 0;
          if (token.type === "long") {
            loss = token.orderQuantity * (token.closeValue - token.value);
          } else if (token.type === "short") {
            loss = token.orderQuantity * (token.value - token.closeValue);
          }
          totalLoss += loss;
        }
      });
    }
  }

  if (totalTokens === 0) {
    return 0; // to avoid division by zero
  }

  const averageLoss = totalLoss / totalTokens;
  return parseFloat(averageLoss).toFixed(2);
}

export function calculateAverageLossPercentage(portfolio, investmentValue) {
  let totalLossPercentage = 0;

  // Total investment value of all tokens
  let totalInvestmentValue = 0;

  // Iterate through each token array in the portfolio object
  for (const key in portfolio) {
    if (Array.isArray(portfolio[key])) {
      portfolio[key].forEach((token) => {
        if (!token.active && !token.profit) {
          totalInvestmentValue += token.orderQuantity * token.value;

          let lossPercentage = 0;
          if (token.type === "long") {
            const loss = token.orderQuantity * (token.closeValue - token.value);
            lossPercentage = (loss / (token.orderQuantity * token.value)) * 100;
          } else if (token.type === "short") {
            const loss = token.orderQuantity * (token.value - token.closeValue);
            lossPercentage = (loss / (token.orderQuantity * token.value)) * 100;
          }
          totalLossPercentage += lossPercentage;
        }
      });
    }
  }

  if (totalInvestmentValue === 0) {
    return 0; // to avoid division by zero
  }

  const averageLossPercentage =
    (totalLossPercentage / totalInvestmentValue) * 100;

  const investmentDepreciation =
    (averageLossPercentage / 100) * investmentValue;
  return investmentDepreciation;
}
