import React from "react";
import "./Tiles.css";
import { largeMoneyValueFormatter } from "../../utils/helpers";

const MonthlyStatisticsTiles = ({
  stats,
  allTime,
  singleInvestmentLimit = 0,
}) => {
  const { pnl, percentageChange, totalOrders, losses, profits, avgProfitLoss } =
    stats;
  const { averageLossPercentage, averageProfitPercentage } = avgProfitLoss;
  const avgUsdProfit = (singleInvestmentLimit * averageProfitPercentage) / 100;
  const avgUsdLoss = (singleInvestmentLimit * averageLossPercentage) / 100;

  const expectancy =
    (profits / totalOrders) * avgUsdProfit -
      (losses / totalOrders) * avgUsdLoss || 0;

  const orderNumber = allTime ? 600 : 50;
  const yearlyExpectancy = (expectancy * orderNumber).toFixed(2);
  const period = allTime ? "1 Year" : "1 Month";

  return (
    <div className="container">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
        <div className="col mb-4 mt-2">
          <div className={`card ${allTime ? "bg-allyear" : "bg-dark"}`}>
            <div
              className="card-body"
              style={
                allTime
                  ? { border: "1px solid grey", borderRadius: "10px" }
                  : null
              }
            >
              <div className="d-flex">
                <div style={{ color: "grey" }}>
                  <h2 className="card-title">Orders</h2>
                </div>{" "}
              </div>
              <span
                className="card-title"
                style={{ fontSize: "2rem", color: "grey" }}
              >
                {totalOrders} (
                <span style={{ color: "lightgreen", marginLeft: "5px" }}>
                  {profits}
                </span>
                /<span style={{ color: "#FF8080" }}>{losses}</span> )
              </span>

              <div
                className="d-flex"
                style={{
                  marginTop: "10px",
                  color: "grey",
                }}
              >
                <div>
                  Avg Profit{" "}
                  <span style={{ color: "lightgreen" }}>
                    {avgProfitLoss.averageProfitPercentage}%
                  </span>
                </div>
                <div className="ms-3">
                  Avg Loss{" "}
                  <span style={{ color: "#FF8080" }}>
                    {avgProfitLoss.averageLossPercentage}%
                  </span>
                </div>
              </div>
              <div className="d-flex" style={{ color: "grey" }}>
                Expectancy ({period})
                <div
                  style={{
                    marginLeft: "10px",
                    color: yearlyExpectancy > 0 ? "lightgreen" : "#ff8080",
                  }}
                >
                  ${largeMoneyValueFormatter(Math.abs(yearlyExpectancy))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col mb-4 mt-2">
          <div className={`card ${allTime ? "bg-allyear" : "bg-dark"}`}>
            <div
              className="card-body"
              style={
                allTime
                  ? { border: "1px solid grey", borderRadius: "10px" }
                  : null
              }
            >
              <div className="d-flex">
                <div style={{ color: "grey" }}>
                  <h2 className="card-title">P/L Result</h2>
                </div>{" "}
              </div>
              <h2
                className="card-title"
                style={{
                  fontSize: "2rem",
                  color: pnl < 0 ? "#FF8080" : "lightgreen",
                }}
              >
                ${Math.abs(pnl).toFixed(2)}
              </h2>
            </div>
          </div>
        </div>

        <div className="col mb-4 mt-2">
          <div className={`card ${allTime ? "bg-allyear" : "bg-dark"}`}>
            <div
              className="card-body"
              style={
                allTime
                  ? { border: "1px solid grey", borderRadius: "10px" }
                  : null
              }
            >
              <div className="d-flex">
                <div style={{ color: "grey" }}>
                  <h2 className="card-title">Total Assets Change</h2>
                </div>{" "}
              </div>
              <span
                style={{
                  fontSize: "2rem",
                  color: percentageChange >= 0 ? "lightgreen" : "#FF8080",
                }}
              >
                {percentageChange}%
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlyStatisticsTiles;
