function calculateAverageProfitAndLossPercentage(orders) {
  // Filter orders where profit is true and type is either 'long' or 'short'
  const profitableOrders = orders.filter(
    (order) => order.profit && (order.type === "long" || order.type === "short")
  );
  // Filter orders where profit is false and type is either 'long' or 'short'
  const lossOrders = orders.filter(
    (order) =>
      !order.profit && (order.type === "long" || order.type === "short")
  );

  // Calculate total profit and total loss percentages
  let totalProfitPercentage = 0;
  let totalLossPercentage = 0;

  profitableOrders.forEach((order) => {
    // Calculate profit percentage for each order
    const percentage =
      order.type === "long"
        ? ((order.closeValue - order.value) / order.value) * 100
        : ((order.value - order.closeValue) / order.value) * 100;

    totalProfitPercentage += percentage;
  });

  lossOrders.forEach((order) => {
    // Calculate loss percentage for each order
    const percentage =
      order.type === "long"
        ? ((order.value - order.closeValue) / order.value) * 100
        : ((order.closeValue - order.value) / order.value) * 100;

    totalLossPercentage += percentage;
  });

  // Calculate average profit and loss percentages
  const averageProfitPercentage =
    profitableOrders.length > 0
      ? (totalProfitPercentage / profitableOrders.length).toFixed(2)
      : 0;
  const averageLossPercentage =
    lossOrders.length > 0
      ? (totalLossPercentage / lossOrders.length).toFixed(2)
      : 0;

  return {
    averageProfitPercentage,
    averageLossPercentage,
  };
}

const calculateExactStatistics = (orders) => {
  let exitValue = 0;
  let totalProfit = 0;
  let entryValue = 0; // Cumulated value instead of totalOrderQuantity

  for (const order of orders) {
    const {
      value,
      type,
      orderQuantity,
      closeValue,
      stream = "performing",
    } = order;

    let orderTotalValue = 0;
    let orderTotalProfit = 0;

    if (
      stream === "trending" ||
      stream === "performing" ||
      stream === "surfing"
    ) {
      if (type === "long") {
        orderTotalValue = closeValue * orderQuantity;
        orderTotalProfit = orderTotalValue - value * orderQuantity;
      } else if (type === "short") {
        orderTotalValue = value * orderQuantity;
        orderTotalProfit = orderTotalValue - closeValue * orderQuantity;
      }
    }

    if (type === "short") {
      entryValue += orderTotalValue;
    } else {
      exitValue += orderTotalValue;
    }

    totalProfit += orderTotalProfit;

    if (type === "short") {
      exitValue += value * orderQuantity;
    } else {
      entryValue += value * orderQuantity;
    }

    // Reverse values for short orders
  }

  return {
    pnl: Number(totalProfit),
  };
};

const getAllClosedOrders = (
  userPortfolio,
  performingChecked,
  trendingChecked,
  surfingChecked
) => {
  // const strategies = Object.keys(userPortfolio);
  const strategies = [];

  if (performingChecked) strategies.push("performingTokens");
  if (trendingChecked) strategies.push("trendingTokens");
  if (surfingChecked) strategies.push("surfingTokens");

  let combinedArray = [];

  if (strategies.length > 0) {
    strategies.forEach((strategy) => {
      combinedArray = combinedArray.concat(userPortfolio[strategy]);
    });

    return combinedArray.filter((order) => order.status === "CLOSED");
  }

  return [];
};

const calculatePercentageChange = (userVault, pnl) => {
  const percentageChange = (1 - (userVault - pnl) / userVault) * 100;
  return Number(percentageChange);
};

function filterOrdersByDate(
  orders,
  selectedMonth,
  selectedWeek,
  selectedDay,
  totalAssets
) {
  // Convert selectedMonth, selectedWeek, and selectedDay to Date objects
  selectedMonth = new Date(selectedMonth);
  selectedWeek = new Date(selectedWeek);
  selectedDay = new Date(selectedDay);

  // Filter orders for monthly summary
  const monthlyOrders = orders.filter((order) => {
    const closeTime = new Date(order.closeTime);
    return (
      closeTime.getFullYear() === selectedMonth.getFullYear() &&
      closeTime.getMonth() === selectedMonth.getMonth()
    );
  });

  // Filter orders for weekly summary
  const nextWeek = new Date(selectedWeek);
  nextWeek.setDate(selectedWeek.getDate() + 7); // Get next 7 days

  const weeklyOrders = orders.filter((order) => {
    const closeTime = new Date(order.closeTime);

    return closeTime >= selectedWeek && closeTime < nextWeek;
  });

  // Filter orders for daily summary
  const dailyOrders = orders.filter((order) => {
    const closeTime = new Date(order.closeTime);

    // Create start and end of selected day
    const startOfDay = new Date(selectedDay);
    startOfDay.setHours(0, 0, 0, 0); // Set to 00:00:00.000
    const endOfDay = new Date(selectedDay);
    endOfDay.setHours(23, 59, 59, 999); // Set to 23:59:59.999

    return closeTime >= startOfDay && closeTime <= endOfDay;
  });

  // Calculate losses and profits
  const calculateLossesAndProfits = (orders) => {
    const losses = orders.filter((order) => order.profit === false).length;
    const lossingOrders = orders.filter((order) => order.profit === false);
    console.log("losses:", losses, lossingOrders);

    const profits = orders.filter((order) => order.profit === true).length;
    const profitingOrders = orders.filter((order) => order.profit === true);
    console.log("profits:", profits, profitingOrders);
    return { losses, profits };
  };

  // Calculate percentage change
  const calculatePercentageChange = (totalAssets, pnl) => {
    const percentageChange = (
      (1 - (totalAssets - pnl) / totalAssets) *
      100
    ).toFixed(2);
    return percentageChange;
  };

  return {
    totalSummary: {
      totalOrders: orders.length,
      orders,
      ...calculateLossesAndProfits(orders),
      ...calculateExactStatistics(orders),
      percentageChange: calculatePercentageChange(
        totalAssets,
        calculateExactStatistics(orders).pnl
      ),
      avgProfitLoss: calculateAverageProfitAndLossPercentage(monthlyOrders),
    },
    monthlySummary: {
      totalOrders: monthlyOrders.length,
      orders: monthlyOrders,
      ...calculateLossesAndProfits(monthlyOrders),
      ...calculateExactStatistics(monthlyOrders),
      percentageChange: calculatePercentageChange(
        totalAssets,
        calculateExactStatistics(monthlyOrders).pnl
      ),
      avgProfitLoss: calculateAverageProfitAndLossPercentage(monthlyOrders),
    },
    weeklySummary: {
      totalOrders: weeklyOrders.length,
      orders: weeklyOrders,
      ...calculateLossesAndProfits(weeklyOrders),
      ...calculateExactStatistics(weeklyOrders),
      percentageChange: calculatePercentageChange(
        totalAssets,
        calculateExactStatistics(weeklyOrders).pnl
      ),
    },
    dailySummary: {
      totalOrders: dailyOrders.length,
      orders: dailyOrders,
      ...calculateLossesAndProfits(dailyOrders),
      ...calculateExactStatistics(dailyOrders),
      percentageChange: calculatePercentageChange(
        totalAssets,
        calculateExactStatistics(dailyOrders).pnl
      ),
    },
  };
}

export function calculateStatistics(
  userPortfolioData,
  selectedMonth,
  selectedWeek,
  selectedDay,
  performingChecked,
  trendingChecked,
  surfingChecked,
  totalFinances
) {
  const totalAssets = totalFinances?.totalAssets || 0;
  const { userPortfolio } = userPortfolioData;

  const orders = getAllClosedOrders(
    userPortfolio,
    performingChecked,
    trendingChecked,
    surfingChecked
  );

  const avgProfitLoss = calculateAverageProfitAndLossPercentage(orders);

  const summary = filterOrdersByDate(
    orders,
    selectedMonth,
    selectedWeek,
    selectedDay,
    totalAssets,
    avgProfitLoss
  );

  return summary;
}
