import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { largeMoneyValueFormatter } from "../../utils/helpers";
import { calculatePercentageChange } from "../../utils/finances";

const AssetTile = ({
  title,
  value,
  refresh,
  isRefreshing = false,
  userInitialInvestment = 0,
  totalAssets = 0,
  totalDebt = 0,
}) => {
  let totalAssetsPercentageChange = calculatePercentageChange(
    Number(totalAssets) - Number(totalDebt),
    userInitialInvestment
  );

  if (title === "Portfolio") {
    totalAssetsPercentageChange = calculatePercentageChange(
      Number(totalAssets) + Number(value) - Number(totalDebt),
      totalAssets
    );
  }

  return (
    <div
      className="text-center bg-dark"
      style={{
        borderRadius: "0.5rem",
        padding: "15px",
        height: "175px",
        margin: "5px",
      }}
      onClick={refresh}
    >
      <div style={{ color: "grey", fontSize: "1rem" }}>
        {title.toUpperCase()}
      </div>

      <div
        style={{
          color: value > 0 ? "lightgreen" : value < 0 ? "#FF8080" : "grey",
          fontSize: "2.5rem",
        }}
      >
        ${largeMoneyValueFormatter(value)}
      </div>
      {title === "Total Capital Gain" || title === "Portfolio" ? (
        <div
          style={{
            fontSize: "0.8rem",
            color: "grey",
          }}
        >
          {totalAssetsPercentageChange > 0 ? "+" : null}
          {totalAssetsPercentageChange}%
        </div>
      ) : null}
      <div className="mt-2">
        <FontAwesomeIcon
          icon={faArrowsRotate}
          style={{
            fontSize: "1rem",
            color: "grey",

            border: "1px solid #333",
            padding: "10px",
            borderRadius: "50%",
          }}
          spin={isRefreshing}
        />
      </div>
    </div>
  );
};

export default AssetTile;
