// import "../../App.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import "./dashboard.css";
import { getMarketSentiment } from "../../utils/dataService";
import Statistics from "./Statistics";
import Orders from "./Orders";
import Menu from "./Menu";
import Tester from "../analytics/Tester";
import BinanceAccount from "./BinanceAccount";
import KucoinAccount from "./KucoinAccount";
import UserSettings from "./UserSettings";
import Welcome from "./Welcome";
import Portfolio360 from "./Portfolio360";
import Spinner from "../../img/spinner.gif";
import Prospects from "./Prospects";

const LiveDashboard = ({ username, trial, userId }) => {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [sentiments, setSentiments] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [url, setUrl] = useState("marketoverview");

  useEffect(() => {
    const updateSentiments = async () => {
      const { data: sentimets } = await getMarketSentiment();
      setSentiments(sentimets.sentiments);
    };

    setShowResults(true);
    updateSentiments();
  }, [cookies, navigate, removeCookie]);

  return (
    <div className="container-fluid">
      <Menu setUrl={setUrl} username={username} trial={trial} />
      {!showResults && url === "marketoverview" && (
        <div className="container mt-3 d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
          <img src={Spinner} alt="spinner" />
        </div>
      )}
      {showResults && url === "marketoverview" && <Orders sentiments={sentiments} />}
      {showResults && url === "statistics" && <Statistics />}
      {showResults && url === "orders" && <Orders sentiments={sentiments} />}
      {showResults && url === "prospects" && <Prospects />}
      {showResults && url === "binanceaccount" && <BinanceAccount />}
      {showResults && url === "kucoinaccount" && <KucoinAccount />}
      {showResults && url === "usersettings" && <UserSettings />}
      {showResults && url === "welcome" && <Welcome />}
      {showResults && url === "portfolio360" && <Portfolio360 />}
    </div>
  );
};

export default LiveDashboard;
