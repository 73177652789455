import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import validator from "validator";
import { register } from "../utils/authService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import CryptoBird from "../img/cryptobird_logo_name.png";

const Register = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    tradingExperience: "",
    cex: "",
    demoAccountVault: "",
  });
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [error, setError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");

  const handleCheckboxChange = (e) => {
    setAgreeTerms(e.target.checked);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    // Validate email format
    if (name === "email") {
      if (validator.isEmail(value)) {
        setError("");
      } else {
        setError("Enter valid Email!");
      }
    }

    // Password strength feedback
    if (name === "password") {
      setInputValue({
        ...inputValue,
        [name]: value,
      });

      // Check if password length is greater than 10 characters
      if (value.length < 10) {
        setPasswordStrength("Password must be at least 10 characters long!");
      } else {
        // Check if password contains at least one special character and one uppercase letter
        if (!/(?=.*[!@#$%^&*()])/.test(value)) {
          setPasswordStrength(
            "Password must contain at least one special character!"
          );
        } else {
          setPasswordStrength("Strong password");
        }
      }
    } else {
      setInputValue({
        ...inputValue,
        [name]: value,
      });
    }

    // Match password and confirm password
    if (name === "confirmPassword") {
      if (value !== inputValue.password) {
        setError("Passwords do not match!");
      } else {
        setError("");
      }
    }
  };

  const handleError = (err) => {
    toast.error(err, {
      position: "top-right",
    });
    setIsLoading(false);
    setInputValue({
      ...inputValue,
      username: "",
      password: "",
      confirmPassword: "",
    });
  };

  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: "bottom-left",
    });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Remove confirmPassword field from inputValue
    const { confirmPassword, ...restInputValue } = inputValue;

    const credentials = { ...restInputValue, agreeTerms };

    try {
      const { data } = await register(credentials);
      const { success, message } = data;

      if (success) {
        handleSuccess(message);
        setIsLoading(false);
        navigate("/welcome");
      } else {
        handleError(message);
      }
    } catch (error) {
      console.log(error.response.status);
    }

    // Reset inputs after submission
    setInputValue({
      ...inputValue,
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      confirmPassword: "",
      email: "",
      country: "",
      tradingExperience: "",
      cex: "",
      demoAccountVault: "",
    });
  };

  const style = {
    backgroundColor: "transparent",
    borderRadius: "0.5rem",
    padding: "0.5rem",
    color: "lightgrey",
  };

  return (
    <div
      className="container"
      style={{
        background: "#000",
        padding: "1.5rem",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        flexDirection: "column",
      }}
    >
      <div>
        <img
          src={CryptoBird}
          style={{ height: "250px", marginBottom: "40px" }}
          alt="cryptobird"
        />
      </div>
      <div style={{ textAlign: "left" }}>
        <h4
          style={{
            color: "grey",
            marginBottom: "5px",
            marginTop: "15px",
            fontSize: "1rem",
          }}
        >
          {" "}
          Please fill the form to apply for CryptoBird's trial account:
        </h4>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="form-group mt-2">
          <input
            type="text"
            value={inputValue.firstName}
            className="form-control"
            name="firstName"
            placeholder="First Name"
            onChange={handleOnChange}
            required
            style={{ ...style, fontSize: "1.4rem", border: "1px solid #333" }}
          />
        </div>
        <div className="form-group mt-2">
          <input
            type="text"
            value={inputValue.lastName}
            className="form-control"
            name="lastName"
            placeholder="Last Name"
            onChange={handleOnChange}
            required
            style={{ ...style, fontSize: "1.4rem", border: "1px solid #333" }}
          />
        </div>

        <div className="form-group mt-2">
          <input
            type="email"
            value={inputValue.email}
            className="form-control"
            name="email"
            placeholder="Email"
            onChange={handleOnChange}
            required
            style={{ ...style, fontSize: "1.4rem", border: "1px solid #333" }}
          />
        </div>
        <div className="form-group mt-2">
          <input
            type="text"
            value={inputValue.username}
            className="form-control"
            name="username"
            placeholder="Username"
            onChange={handleOnChange}
            required
            style={{ ...style, fontSize: "1.4rem", border: "1px solid #333" }}
          />
        </div>
        <div className="form-group mt-2">
          <input
            value={inputValue.password}
            type="password"
            className="form-control"
            name="password"
            placeholder="Password"
            onChange={handleOnChange}
            required
            style={{ ...style, fontSize: "1.4rem", border: "1px solid #333" }}
          />
          <div className="password-strength" style={{ color: "grey" }}>
            {passwordStrength}
          </div>
        </div>
        <div className="form-group mt-2">
          <input
            value={inputValue.confirmPassword}
            type="password"
            className="form-control"
            name="confirmPassword"
            placeholder="Confirm Password"
            onChange={handleOnChange}
            required
            style={{ ...style, fontSize: "1.4rem", border: "1px solid #333" }}
          />
        </div>
        <div className="form-group mt-2">
          <input
            value={inputValue.country}
            type="text"
            className="form-control"
            name="country"
            placeholder="Country"
            onChange={handleOnChange}
            required
            style={{ ...style, fontSize: "1.4rem", border: "1px solid #333" }}
          />
        </div>
        <div className="form-group mt-2">
          <select
            value={inputValue.tradingExperience}
            className="form-control"
            name="tradingExperience"
            onChange={handleOnChange}
            style={{
              backgroundColor: "transparent",
              borderRadius: "0.5rem",
              padding: "0.5rem",
              color: "grey",
              cursor: "pointer",
              fontSize: "1.4rem",
              border: "1px solid #333",
            }}
            required
          >
            <option value="">Trading Experience</option>
            <option value="Beginner">Beginner</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Advanced">Advanced</option>
            <option value="Expert">Expert</option>
            <option value="Professional">Professional</option>
          </select>
        </div>
        <div className="form-group mt-2">
          <select
            value={inputValue.cex}
            className="form-control"
            name="cex"
            onChange={handleOnChange}
            style={{
              backgroundColor: "transparent",
              borderRadius: "0.5rem",
              color: "grey",
              padding: "0.5rem",
              cursor: "pointer",
              fontSize: "1.4rem",
              border: "1px solid #333",
            }}
            required
          >
            <option value="">Preferred Exchange</option>
            <option value="Binance">Binance</option>
            <option value="Kucoin">Kucoin</option>
            <option value="Coinbase">Coinbase</option>
            <option value="Kraken">Kraken</option>
            <option value="Bitfinex">No preference</option>
          </select>
        </div>
        <div className="form-group mt-2">
          <select
            value={inputValue.demoAccountVault}
            className="form-control"
            name="demoAccountVault"
            onChange={handleOnChange}
            style={{
              backgroundColor: "transparent",
              borderRadius: "0.5rem",
              padding: "0.5rem",
              color: "grey",
              cursor: "pointer",
              fontSize: "1.4rem",
              border: "1px solid #333",
            }}
            required
          >
            <option value="">Desired Demo Budget</option>
            <option value="2500">$2.5K</option>
            <option value="10000">$10K</option>
            <option value="50000">$50K</option>
            <option value="100000">$100K</option>
            <option value="250000">$250K</option>
            <option value="1000000">$1M</option>
          </select>
        </div>
        <div className="form-check mt-2">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckChecked"
            checked={agreeTerms}
            onChange={handleCheckboxChange}
            required
          />
          <label
            className="form-check-label"
            htmlFor="flexCheckChecked"
            style={{ color: "grey" }}
          >
            I agree to the CryptoBird's trial terms and conditions
          </label>
        </div>
        <div className="m-3">
          <span style={{ color: "red" }}>{error}</span>
        </div>
        <button
          className="btn btn-dark mt-3 btn-block"
          type="submit"
          disabled={agreeTerms === false}
          style={{
            width: "100%",
            fontSize: "1.4rem",
            color: "#fff",
            backgroundColor: agreeTerms === false ? "grey" : "green",

            padding: "0.5rem", // Simplified padding
            marginTop: "1rem",
          }}
        >
          {isLoading ? (
            <FontAwesomeIcon icon={faGear} size="lg" spin />
          ) : (
            "APPLY "
          )}
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Register;
