import React, { useState, useEffect } from "react";
import { getKucoinWalletInfo, getKucoinLiabilities } from "../../utils/dataService";
import { formatKucoinWalletResponse } from "../../utils/helpers";
import KucoinLogo from "../../img/kucoinLogo.png";
import Spinner from "../../img/spinner.gif";

const KucoinAccount = () => {
  const [balances, setBalances] = useState([]);
  const [assets, setAssets] = useState([]); // [ {name: "BTC", total: 0.00000000, type: "trade", usdValue: 0.00}
  const [zeroBalance, setZeroBalance] = useState([]); // [ {name: "BTC", total: 0.00000000, type: "trade", usdValue: 0.00}
  const [usdt, setUsdt] = useState(0); // [ {name: "BTC", total: 0.00000000, type: "trade", usdValue: 0.00}
  const [isLoading, setIsloading] = useState(true);
  // const [totalUsdValue, setTotalUsdValue] = useState(0);

  useEffect(() => {
    const fetchBalances = async () => {
      const { data } = await getKucoinWalletInfo();

      const { tickers, data: tokens } = data;

      const { data: liabilities } = await getKucoinLiabilities();

      const { kucoinTokens } = formatKucoinWalletResponse(liabilities.data, tickers);

      const kucoinTokensSorted = kucoinTokens.sort((a, b) => b.liability - a.liability);
      const zeroBalance = kucoinTokensSorted.filter((token) => token.liability > 1);
      setBalances(zeroBalance);
      setUsdt(liabilities.maxBorrowSize);
      setAssets(tokens);
      setIsloading(false);
    };
    fetchBalances();
  }, []);

  if (!isLoading) {
    return (
      <div className="container">
        <div className="row">
          <div className="d-flex col-6 mt-3">
            <img src={KucoinLogo} alt="binance" style={{ height: "50px" }} />
            <h1 className="ms-3">Kucoin Account</h1>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <h3>
              Available: <span style={{ color: "lightgreen" }}>${(usdt / 1000).toFixed(0)}K</span>
            </h3>
          </div>
        </div>
        {/* <div className="row">
          <div className="col">
            <div style={{ padding: "10px" }}>
              {" "}
              <h3>Assets</h3>
              <table className="table table-dark text-light mt-3 rounded rounded-3 overflow-hidden">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Token</th>
                    <th scope="col">Balance</th>
                    <th scope="col">Account</th>
                  </tr>
                </thead>
                <tbody>
                  {assets.length > 0 &&
                    assets.map((token, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{token.currency}</td>
                          <td>{token.balance}</td>
                          <td>
                            <span className="badge bg-dark text-light">{token.type.toUpperCase()}</span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col">
            <div style={{ padding: "10px" }}>
              {" "}
              <h3>Liabilities</h3>
              <table className="table table-dark text-light mt-3 rounded rounded-3 overflow-hidden">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Token</th>
                    <th scope="col">Balance</th>
                    <th scope="col">Debt</th>
                  </tr>
                </thead>
                <tbody>
                  {balances.length > 0 &&
                    balances.map((token, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{token.name}</td>
                          <td>{token.usdValue}</td>
                          <td style={{ color: "#FF8080" }}>${token.liability}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container mt-3 d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
        <img src={Spinner} alt="spinner" />
      </div>
    );
  }
};

export default KucoinAccount;
