import React, { useState } from "react";
import "./Portfolio.css";
import Spinner from "../../img/spinner8.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import TokenCircle from "../elements/TokenCircle";
import { addFundingTokens, removeFundingTokens } from "../../utils/users";
import InfoTile from "../elements/InfoTile";

const Portfolio = ({ user, title, strategy, maxLength = 1, refreshUser }) => {
  const [selectToken, setSelectToken] = useState(false);
  //const [strategySelected, setStrategySelected] = useState("");
  const [inputValue, setInputValue] = useState({});
  const [options, setOptions] = useState([]);
  const [fundingToken, setFundingToken] = useState("+");
  const [isLoading, setIsLoading] = useState(false);
  const [rebalancingTokensInfo, setRebalancingTokensInfo] = useState(false);

  const handleShowRebalancingTokenInfo = () => {
    setRebalancingTokensInfo(!rebalancingTokensInfo);
  };

  const handleCloseInfo = () => {
    setRebalancingTokensInfo(false);
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setInputValue({
      [name]: value,
    });
  };

  const sectorStrategies = {
    aiTokens: ["RNDR", "FET", "AGIX", "OCEAN"],
    gamingTokens: ["ICP", "IMX", "SAND", "AXS"],
    fundingTokens: [
      "BTC",
      "ETH",
      "SOL",
      "HBAR",
      "BNB",
      "XRP",
      "ADA",
      "AVAX",
      "LINK",
    ],
  };

  const tokens =
    strategy === "fundingTokens"
      ? [...user.balances]
      : user.demoPortfolio[strategy];

  if (tokens.length < maxLength) {
    const diff = maxLength - tokens.length;
    for (let i = 0; i < diff; i++) {
      tokens.push({ name: "+" });
    }
  } else if (tokens.length > maxLength) {
    tokens.length = maxLength; // Truncate the array to maxLength
  } else if (tokens.length === 0) {
    tokens.push({ name: "+" });
  }

  const onClick = (name) => {
    if (name !== "+") setFundingToken(name);
    setSelectToken(true);
    // setStrategySelected(strategy);

    const strategyOptions = sectorStrategies[strategy];
    setOptions(strategyOptions);
  };

  const handleFundingTokenActions = async ({ action }) => {
    if (action === "CANCEL") {
      setSelectToken(false);
      setInputValue({});
      return;
    } else if (action === "ADD") {
      setIsLoading(true);
      const data = await addFundingTokens(inputValue.fundingTokens);
      if (data.data.success) setIsLoading(false);
    } else if (action === "REMOVE") {
      setIsLoading(true);
      const { data } = await removeFundingTokens(fundingToken);
      if (data.success) {
        setFundingToken("+");
        setIsLoading(false);
      }
    }
    setSelectToken(false);
    refreshUser();
  };

  console.log("fundingToken", fundingToken);

  return (
    <div className="col-12 mt-3" style={{ fontSize: "1.2rem", color: "#fff" }}>
      {isLoading && (
        <div
          style={{
            background: "#000",
            borderRadius: "0.5rem",
            margin: "10px",
            padding: "10px",
            height: "145px",
          }}
        >
          {" "}
          <img src={Spinner} height="100px" />
        </div>
      )}

      {!isLoading && (
        <div
          style={{
            background: "#000",
            borderRadius: "0.5rem",
            margin: "10px",
            padding: "10px",
            height: "145px",
          }}
        >
          <h4>
            {title} <span style={{ color: "grey" }}>Tokens</span>
            <span
              style={{ color: "#333", marginLeft: "10px", cursor: "pointer" }}
            >
              <FontAwesomeIcon
                icon={faCircleInfo}
                onClick={handleShowRebalancingTokenInfo}
              />
            </span>
          </h4>
          {rebalancingTokensInfo && (
            <div className="container">
              <InfoTile keyword="TOKENS" onClose={handleCloseInfo} />
            </div>
          )}
          {!selectToken && (
            <div className="mt-2" style={{ display: "flex", flexWrap: "wrap" }}>
              {tokens.map((token, index) => (
                <TokenCircle
                  name={
                    user.balances.length > 0 ? token.token.name : token.name
                  }
                  isActive={token.name === "+" ? false : token.token.isActive}
                  key={index}
                  edit={true}
                  style={{ flex: "0 0 50%", maxWidth: "50%" }}
                  onClick={() =>
                    onClick(
                      user.balances.length > 0 ? token.token.name : token.name
                    )
                  }
                />
              ))}
            </div>
          )}
          {selectToken && (
            <div className="row">
              {fundingToken === "+" && (
                <div className="col-5">
                  <div className="form-group mt-2">
                    <select
                      value={inputValue.token}
                      className="form-control"
                      name={strategy}
                      onChange={handleOnChange}
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: "0.5rem",
                        padding: "0.5rem",
                        color: "lightgrey",
                        cursor: "pointer",
                      }}
                      required
                    >
                      <option value="">Select Token</option>
                      {options &&
                        options.length > 0 &&
                        options.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              )}
              {fundingToken !== "+" && (
                <div className="d-flex col-3 form-group mt-2 ms-3 align-items-center ">
                  {fundingToken}
                </div>
              )}
              {(inputValue?.fundingTokens || fundingToken !== "+") && (
                <div className="col-6 d-flex align-items-center fade-in">
                  <div className="btn-group">
                    <button
                      className={`btn ${
                        fundingToken === "+" ? "btn-success" : "btn-danger"
                      } mt-2 ms-4`}
                      onClick={() =>
                        handleFundingTokenActions({
                          action: fundingToken === "+" ? "ADD" : "REMOVE",
                        })
                      }
                    >
                      {fundingToken === "+" ? "ADD" : "REMOVE"}
                    </button>
                    <button
                      className="btn btn-warning mt-2"
                      onClick={() =>
                        handleFundingTokenActions({ action: "CANCEL" })
                      }
                    >
                      CANCEL
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Portfolio;
