const PageNotFound = () => {
  return (
    <div className="container">
      <div className="mt-5"></div>
      <h3>Page not found.</h3>
    </div>
  );
};

export default PageNotFound;
