import http from "./httpService";

const apiPublicServer = process.env.REACT_APP_PUBLIC_BACKEND;

export function login(credentials) {
  return http.post(`${apiPublicServer}login`, credentials);
}

export function register(credentials) {
  return http.post(`${apiPublicServer}register`, credentials);
}

export function authUser() {
  return http.post(`${apiPublicServer}auth`);
}

export function verifyToken(token) {
  return http.post(`${apiPublicServer}verifyToken`, { token });
}
