import React from "react";

const DailyStatisticsTiles = ({ stats }) => {
  const {
    orders = [],
    percentageChange,
    losses,
    secured,
    profits,
    pnl,
  } = stats;

  const results = [
    { name: "Orders", value: orders.length },
    { name: "Losses", value: losses },
    { name: "Profits", value: profits },
  ];

  const calculateProfitsPercentage = () => {
    const result = ((profits + secured) / orders.length) * 100;
    if (!result) return 0;

    return result.toFixed(0);
  };

  return (
    <div className="container">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
        <div className="col mb-4">
          <div className="card bg-dark">
            <div className="card-body">
              <div className="d-flex">
                <div style={{ color: "grey" }}>
                  <h2 className="card-title">Orders</h2>
                </div>{" "}
              </div>
              <span
                className="card-title"
                style={{ fontSize: "2rem", color: "grey" }}
              >
                {orders.length} (
                <span style={{ color: "lightgreen", marginLeft: "5px" }}>
                  {profits}
                </span>
                /<span style={{ color: "#FF8080" }}>{losses}</span> )
              </span>
            </div>
          </div>
        </div>
        <div className="col mb-4">
          <div className="card bg-dark">
            <div className="card-body">
              <div className="d-flex">
                <div style={{ color: "grey" }}>
                  <h2 className="card-title">P/L Result</h2>
                </div>{" "}
              </div>
              <h2
                className="card-title"
                style={{
                  fontSize: "2rem",
                  color: pnl < 0 ? "#FF8080" : "lightgreen",
                }}
              >
                ${Math.abs(pnl).toFixed(2)}
              </h2>
            </div>
          </div>
        </div>
        <div className="col mb-4">
          <div className="card bg-dark">
            <div className="card-body">
              <div className="d-flex">
                <div style={{ color: "grey" }}>
                  <h2 className="card-title">Total Assets Change</h2>
                </div>{" "}
              </div>
              <span
                style={{
                  fontSize: "2rem",
                  color: percentageChange >= 0 ? "lightgreen" : "#FF8080",
                }}
              >
                {percentageChange}%
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">
        {/* <div className="col mb-4">
          <div className="card bg-dark">
            <div className="card-body">
              <div className="d-flex">
                <div>
                  <h2 className="card-title">Success Ratio</h2>
                </div>{" "}
              </div>
              <span style={{ fontSize: "2rem", color: "lightgreen" }}>{calculateProfitsPercentage()}%</span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DailyStatisticsTiles;
