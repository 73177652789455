import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const TokenCircle = ({ name, onClick, isActive }) => {
  const styles = {
    circle: {
      width: "70px",
      height: "70px",
      borderRadius: "50%",
      backgroundColor: isActive ? "rgba(33, 150, 243, 0.6)" : "grey", // Dark background color
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: isActive ? "#fff" : "#333", // Text color
      fontSize: "18px",
      textAlign: "center",
      cursor: "pointer",
    },
    text: {
      margin: "auto",
    },
  };

  const transparentCircleStyles = {
    ...styles.circle,
    backgroundColor: "transparent",
    border: "2px solid grey", // White border
  };

  const circleStyles = name === "+" ? transparentCircleStyles : styles.circle;
  let shortName = name.split("USDT")[0];

  if (shortName === "+") {
    shortName = <FontAwesomeIcon icon={faPlus} style={{ color: "#fff" }} />;
  }

  return (
    <div className="d-flex align-items-center mt-2 me-2 ms-2">
      <div style={circleStyles}>
        <span style={styles.text} onClick={onClick}>
          {shortName}
        </span>{" "}
      </div>{" "}
    </div>
  );
};

TokenCircle.propTypes = {
  name: PropTypes.string.isRequired,
};

export default TokenCircle;
