import React, { useState, useEffect } from "react";
import { getBinanceWalletInfo } from "../../utils/dataService";
import { formatBinanceWalletResponse } from "../../utils/helpers";
import Spinner from "../../img/spinner.gif";
import BianceLogo from "../../img/binance.png";

const BinanceAccount = () => {
  const [balances, setBalances] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [totalUsdValue, setTotalUsdValue] = useState(0);

  function formatNumber(num) {
    if (num < 1) {
      return num.toFixed(8);
    } else {
      return num.toFixed(2);
    }
  }

  useEffect(() => {
    const fetchBalances = async () => {
      const { data } = await getBinanceWalletInfo();

      const { formattedTokens, totalusdValue } = formatBinanceWalletResponse(data);

      const formattedTokensSorted = formattedTokens.sort((a, b) => b.usdValue - a.usdValue);

      setTotalUsdValue(totalusdValue.toFixed(2));
      setBalances(formattedTokensSorted);
      setIsloading(false);
    };
    fetchBalances();
  }, []);

  if (!isLoading) {
    return (
      <div className="container">
        <div className="row">
          <div className="d-flex col-6 mt-3">
            <img src={BianceLogo} alt="binance" style={{ height: "50px" }} />
            <h1 className="ms-3">Binance Account</h1>
          </div>
          <div className="row mt-3">
            <div className="col">
              <h3>
                Available: <span style={{ color: "lightgreen" }}>${(totalUsdValue / 1000).toFixed(2)}K</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div style={{ padding: "10px" }}>
              <h3>Assets</h3>
              <table className="table table-dark text-light mt-3 rounded rounded-3 overflow-hidden">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Token</th>
                    <th scope="col">Total</th>
                    <th scope="col">On Order</th>
                    <th scope="col">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {balances.length > 0 &&
                    balances.map((token, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{token.name}</td>
                          <td>{formatNumber(token.total)}</td>
                          <td>{token.onOrder}</td>
                          <td>${token.usdValue}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container mt-3 d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
        <img src={Spinner} alt="spinner" />
      </div>
    );
  }
};

export default BinanceAccount;
