const Pill = ({ value, handleDateClick, theme = "dark" }) => {
  return (
    <span
      className={`badge text-bg-${theme} me-2`}
      style={{ cursor: "pointer" }}
      onClick={() => handleDateClick(value)}
    >
      {value}
    </span>
  );
};

export default Pill;
