import React, { useState, useEffect } from "react";
import "./Orders.css";
import moment from "moment";
import {
  getLastSixMonthsLastDays,
  getMondaysOfMonth,
  getWeekFromDate,
} from "../../utils/helpers";
import { getUserPortfolio, getPrices } from "../../utils/dataService";
import StatisticsTiles from "../elements/StatisticsTiles";
import WeeklyStatisticsTiles from "../elements/WeeklyStatisticsTiles";
import DailyStatisticsTiles from "../elements/DailyStatisticsTiles";
import MonthlyStatisticsTiles from "../elements/MonthlyStatisticsTiles";
import Spinner from "../../img/anime10.gif";
import Spinner1 from "../../img/spinner8.gif";
import Pill from "../elements/Pill";
import { calculateStatistics } from "../../utils/statistics";
import { calculateTotalFinances } from "../../utils/finances";

const Statistics = () => {
  const [userPortfolio, setUserPortfolio] = useState(null);
  const [weeks, setWeeks] = useState([]);
  const [days, setDays] = useState([]);
  const [months, setMonths] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedWeek, setSelectedWeek] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [loading, setLoading] = useState(true);
  const [weeklyLoading, setWeeklyLoading] = useState(true);
  const [monthlyLoading, setMonthlyLoading] = useState(true);
  const [intradayLoading, setIntradayLoading] = useState(true);
  const [trendingChecked, setTrendingChecked] = useState(true);
  const [performingChecked, setPerformingChecked] = useState(true);
  const [surfingChecked, setSurfingChecked] = useState(true);
  const [statisticsSummary, setStatisticsSummary] = useState(null);
  const [allTime, setAllTime] = useState(false);
  const [totalFinances, setTotalFinances] = useState(null);

  const handleMonthClick = async (date) => {
    console.log("date", date);
    if (date === "LASTYEAR") {
      setAllTime(!allTime);
      setSelectedMonth(null);
      setSelectedWeek(null);
      setSelectedDay(null);
    } else {
      setAllTime(false);
      const month = date;
      setSelectedMonth(date);

      const weeks = getMondaysOfMonth(date);
      const week = weeks[0];
      setWeeks(weeks);

      const days = getWeekFromDate(weeks[0]);
      const day = days[0];
      setDays(days);

      setSelectedWeek(weeks[0]);
      setSelectedDay(moment().format("YYYY-MM-DD"));

      const statisticsSummary = calculateStatistics(
        userPortfolio,
        month,
        week,
        day,
        performingChecked,
        trendingChecked,
        surfingChecked,
        totalFinances
      );

      setStatisticsSummary(statisticsSummary); // Pass the object to Tiles components
    }
  };

  const handleWeekClick = async (date) => {
    setAllTime(false);
    const month = selectedMonth;
    const week = date;

    const days = getWeekFromDate(date);
    setDays(days);
    setSelectedWeek(date);
    const day = days[0];
    setSelectedDay(days[0]);

    const statisticsSummary = calculateStatistics(
      userPortfolio,
      month,
      week,
      day,
      performingChecked,
      trendingChecked,
      surfingChecked,
      totalFinances
    );
    setStatisticsSummary(statisticsSummary); // Pass the object to Tiles components
  };

  const handleDateClick = async (date) => {
    setAllTime(false);
    setSelectedDay(date);
    const day = date;
    const month = selectedMonth;
    const week = selectedWeek;

    const statisticsSummary = calculateStatistics(
      userPortfolio,
      month,
      week,
      day,
      performingChecked,
      trendingChecked,
      surfingChecked,
      totalFinances
    );
    setStatisticsSummary(statisticsSummary); // Pass the object to Tiles components
  };

  useEffect(() => {
    const fetchOrders = async () => {
      const { data: response } = await getPrices();
      const { data: price } = response;

      const months = getLastSixMonthsLastDays();
      setMonths(months);
      setSelectedMonth(months[0].lastDay);

      const lastDayOfMonth = moment().endOf("month").format("YYYY-MM-DD");
      const weeks = getMondaysOfMonth(lastDayOfMonth);

      setWeeks(weeks);
      setSelectedWeek(weeks[0]);

      const days = getWeekFromDate(weeks[0]);
      setDays(days);
      setSelectedDay(moment().format("YYYY-MM-DD"));

      const { data } = await getUserPortfolio();

      if (data) {
        const {
          userPortfolio,
          userVault,
          userBalances,
          userBudget,
          userInitialInvestment,
        } = data;

        const userPortfolioData = {
          userPortfolio,
          userVault,
          userBalances,
          userBudget,
        };

        setUserPortfolio(userPortfolioData);

        const month = months[0].lastDay;
        const week = weeks[0];
        const day = moment().format("YYYY-MM-DD");

        const totalFinancesUpdate = calculateTotalFinances({
          userPortfolio,
          userVault,
          price,
          userInitialInvestment,
        });

        setTotalFinances(totalFinancesUpdate);

        const statisticsSummary = calculateStatistics(
          userPortfolioData,
          month,
          week,
          day,
          performingChecked,
          trendingChecked,
          surfingChecked,
          totalFinancesUpdate
        );

        setStatisticsSummary(statisticsSummary); // Pass the object to Tiles components
        setWeeklyLoading(false);
        setMonthlyLoading(false);
        setIntradayLoading(false);
      }

      setLoading(false);
    };

    fetchOrders();
  }, [performingChecked, trendingChecked, surfingChecked]);

  if (loading) {
    return (
      <div
        className="container mt-3 d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <img src={Spinner} alt="spinner" height="400px" />
      </div>
    );
  }

  return (
    <div className="container">
      <div style={{ color: "grey" }}>
        <h3 style={{ color: "grey" }}>Strategies Filter</h3>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={performingChecked}
            value={performingChecked}
            id="flexCheckDefault"
            style={{
              cursor: "pointer",
              backgroundColor: "#000",
              borderColor: "grey",
            }}
            onChange={() => setPerformingChecked(!performingChecked)}
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Performing
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value={trendingChecked}
            id="flexCheckChecked"
            checked={trendingChecked}
            style={{
              cursor: "pointer",
              backgroundColor: "#000",
              borderColor: "grey",
            }}
            onChange={() => setTrendingChecked(!trendingChecked)}
          />
          <label className="form-check-label" htmlFor="flexCheckChecked">
            Trending
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value={surfingChecked}
            id="flexCheckChecked"
            checked={surfingChecked}
            style={{
              cursor: "pointer",
              backgroundColor: "#000",
              borderColor: "grey",
            }}
            onChange={() => setSurfingChecked(!surfingChecked)}
          />
          <label className="form-check-label" htmlFor="flexCheckChecked">
            Surfing
          </label>
        </div>
      </div>

      <div className="Orders-header mt-5">
        <h3>Months</h3>
        {monthlyLoading && (
          <div
            className="container mt-3 d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <img src={Spinner1} alt="spinner" height="250px" />
          </div>
        )}
        {!monthlyLoading && (
          <div>
            <div
              className="form-control mt-3 d-flex justify-content-between"
              style={{ background: "#000", border: "none" }}
            >
              <div>
                {months.map(({ name, lastDay }) => {
                  return (
                    <Pill
                      key={name}
                      value={name}
                      handleDateClick={() => handleMonthClick(lastDay)}
                      theme={selectedMonth === lastDay ? "light" : "dark"}
                    />
                  );
                })}{" "}
              </div>
              <div>
                <Pill
                  value="All Time"
                  handleDateClick={() => handleMonthClick("LASTYEAR")}
                  theme={allTime ? "light" : "dark"}
                />
              </div>
            </div>
            <MonthlyStatisticsTiles
              stats={
                allTime
                  ? statisticsSummary.totalSummary
                  : statisticsSummary.monthlySummary
              }
              allTime={allTime}
              singleInvestmentLimit={
                userPortfolio.userBudget.singleInvestmentLimit
              }
            />
          </div>
        )}

        {!allTime && (
          <>
            <h3>Weeks</h3>
            {weeklyLoading && (
              <div
                className="container mt-3 d-flex justify-content-center align-items-center"
                style={{ height: "50vh" }}
              >
                <img src={Spinner1} alt="spinner" height="250px" />
              </div>
            )}

            {!weeklyLoading && (
              <div>
                <div className="m-3 d-flex justify-content-between">
                  <div>
                    {weeks.map((date) => {
                      return (
                        <Pill
                          key={date}
                          value={date}
                          handleDateClick={handleWeekClick}
                          theme={selectedWeek === date ? "light" : "dark"}
                        />
                      );
                    })}{" "}
                  </div>
                </div>
                <WeeklyStatisticsTiles
                  stats={statisticsSummary.weeklySummary}
                />
              </div>
            )}
          </>
        )}
        {!allTime && (
          <>
            <h3>Days</h3>

            {intradayLoading && (
              <div
                className="container mt-3 d-flex justify-content-center align-items-center"
                style={{ height: "50vh" }}
              >
                <img src={Spinner1} alt="spinner" height="250px" />
              </div>
            )}
            {!intradayLoading && (
              <div>
                <div className="m-3 d-flex justify-content-between">
                  <div>
                    {days.map((date) => {
                      return (
                        <Pill
                          key={date}
                          value={date}
                          handleDateClick={handleDateClick}
                          theme={selectedDay === date ? "light" : "dark"}
                        />
                      );
                    })}{" "}
                  </div>
                </div>
                <DailyStatisticsTiles stats={statisticsSummary.dailySummary} />
              </div>
            )}
            {statisticsSummary.dailySummary.orders.length > 0 && (
              <div>
                <h3 className="mb-3">Assets</h3>
                <StatisticsTiles
                  orders={statisticsSummary.dailySummary.orders}
                />
                <div style={{ padding: "10px" }}></div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Statistics;
