import http from "./httpService";

const apiPublicServer = process.env.REACT_APP_PUBLIC_BACKEND;

export function getUser() {
  return http.get(`${apiPublicServer}user`);
}

export function putUser(user) {
  return http.put(`${apiPublicServer}user`, { user: user });
}

export function switchCryptoBird(user) {
  return http.put(`${apiPublicServer}cryptobird`, { user: user });
}

export function addFundingTokens(token) {
  return http.post(`${apiPublicServer}fundingTokens`, { token: token });
}

export function removeFundingTokens(token) {
  return http.put(`${apiPublicServer}fundingTokens`, { token: token });
}
