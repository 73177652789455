import React, { useState } from "react";

const Results = ({ monthlySummary }) => {
  const { losses, secured, profits } = monthlySummary;

  const calculateResult = () => {
    const totalCapital = parseInt(process.env.REACT_APP_TOTAL_CAPITAL);

    const singleInvestment =
      parseInt(process.env.REACT_APP_SINGLE_INVESTMENT) * parseInt(process.env.REACT_APP_INVESTMENT_LEVERAGE);

    const result =
      profits * singleInvestment * 0.04 * 0.9992 +
      secured * singleInvestment * 0.021 * 0.9992 -
      losses * singleInvestment * 0.0058 * 1.0008;

    const resultPercentage = (result / totalCapital) * 100;

    return resultPercentage.toFixed(2);
  };

  return (
    <div className="container mt-3">
      <div className="row">
        <div className="col-12">
          <h3
            className="mt-3 d-flex justify-content-center align-items-center"
            style={{ fontSize: "4rem", height: "60vh", color: "lightgreen" }}
          >
            +{calculateResult()}%
          </h3>
        </div>
      </div>

      <div>
        <div className="d-flex"></div>
      </div>
    </div>
  );
};

export default Results;
