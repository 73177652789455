import React, { useState, useEffect } from "react";
import "./Tiles.css";
import {
  calculatePercentageChange,
  calculatePercentageChangeTotal,
  determinePercentageColor,
} from "../../utils/finances";
import {
  convertToHumanReadable,
  largeMoneyValueFormatter,
} from "../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faCircleInfo,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import kucoinLogo from "../../img/kucoinLogo.png";
import binanceLogo from "../../img/binance.png";
import { getPrices } from "../../utils/dataService";
import InfoTile from "./InfoTile";

const PerformingOrdersTiles = ({ orders }) => {
  const [price, setPrice] = useState([]);
  const [totalAvg, setTotalAvg] = useState(0);
  const [showInfo, setShowInfo] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showStratey, setShowStrategy] = useState(false);
  const [showTokens, setShowTokens] = useState(false);

  const handleShowTokens = () => {
    setShowTokens(!showTokens);
  };

  const handleShowStrategy = () => {
    setShowStrategy(!showStratey);
  };

  const handleShowInfo = (orderId) => {
    setSelectedOrder(orderId);
  };

  const handleCloseInfo = () => {
    setSelectedOrder(null);
    setShowStrategy(false);
  };

  useEffect(() => {
    const fetchPrices = async () => {
      const { data: response } = await getPrices();
      const { data: price } = response;

      const portfolioAvg = calculatePercentageChangeTotal({ orders, price });
      setTotalAvg(portfolioAvg);
      //setPerfTotalPnl(portfolioAvg);
      setPrice(price);
    };

    fetchPrices();

    // Set up a timer to fetch data every 5 minutes
    const intervalId = setInterval(() => {
      fetchPrices();
      console.log("Performing tokens price check");
    }, 2 * 60 * 1000); // 2 minutes in milliseconds
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const totalAbs = Math.abs(totalAvg);

  return (
    <div className="">
      <div className="d-flex align-items-center">
        <h4>
          Performing
          {orders.length === 0 && (
            <sup
              className="text-warning"
              style={{
                background: "#333",
                marginLeft: "10px",
                marginRight: "5px",
                fontSize: "0.8rem",
                padding: "3px 6px",
                borderRadius: "5px",
              }}
            >
              No signals yet
            </sup>
          )}
          {orders.length > 0 && (
            <span style={{ marginLeft: "6px", color: "grey" }}>
              {orders.length}
            </span>
          )}
          {orders.length > 0 && (
            <span
              className="ms-2"
              style={{
                color: `${totalAvg >= 0 ? "lightgreen" : "#FF8080"}`,
              }}
            >
              (${largeMoneyValueFormatter(totalAbs)})
            </span>
          )}
          <FontAwesomeIcon
            icon={faCircleInfo}
            style={{
              marginLeft: "10px",
              color: "#333",
              cursor: "pointer",
            }}
            size="1x"
            onClick={handleShowStrategy}
          />
          {orders.length > 0 && (
            <FontAwesomeIcon
              icon={showTokens ? faEye : faEyeSlash}
              style={{
                marginLeft: "15px",
                color: "#333",
                cursor: "pointer",
              }}
              size="1x"
              onClick={handleShowTokens}
            />
          )}
        </h4>{" "}
      </div>{" "}
      {showStratey && (
        <div className="row">
          <InfoTile keyword="PERFORMING" onClose={handleCloseInfo} />
        </div>
      )}
      {showTokens && (
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 rounded fade-in">
          {orders.map((order) => (
            <div className="col mb-3 mt-3" key={order._id}>
              <div className="card bg-dark">
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div>
                      <div
                        className="card-title"
                        onClick={() =>
                          navigator.clipboard.writeText(order.name)
                        }
                        style={{ fontSize: "3rem" }}
                      >
                        {order.name.split("USDT")[0]}
                      </div>
                    </div>
                    <div className="ms-1">
                      <div>
                        <span
                          className={`badge badge bg-dark mb-2`}
                          style={{ color: "grey" }}
                        >
                          {order.type.toUpperCase()}
                          <FontAwesomeIcon
                            icon={faCircleInfo}
                            style={{
                              marginLeft: "5px",
                              color: "#333",
                            }}
                            size="1x"
                            onClick={() => handleShowInfo(order._id)}
                          />
                        </span>

                        {order.isLeveraged && (
                          <span
                            className={`badge badge bg-warning mb-2 ms-3`}
                            style={{
                              fontSize: "0.8rem",
                              fontWeight: "900",
                              color: "#000",
                            }}
                          >
                            x{process.env.REACT_APP_INVESTMENT_LEVERAGE}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {selectedOrder === order._id && (
                    <div className="row">
                      <InfoTile
                        keyword={order.type.toUpperCase()}
                        onClose={handleCloseInfo}
                      />
                    </div>
                  )}
                  <div>
                    <span style={{ color: "grey" }}>
                      {order.orderDate.split(" ")[0]}
                    </span>
                    <FontAwesomeIcon
                      icon={faClock}
                      style={{ marginLeft: "15px", color: "grey" }}
                      size="1x"
                    />
                    <span style={{ color: "grey" }}>
                      {" "}
                      {order.orderDate.split(" ")[1]}
                    </span>
                    <span style={{ marginLeft: "15px" }}>
                      {order.exchange === "binance" ? (
                        <img src={binanceLogo} alt="binance" height="16px" />
                      ) : (
                        <img src={kucoinLogo} alt="binance" height="15px" />
                      )}
                    </span>
                  </div>{" "}
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ color: "grey", marginRight: "11px" }}>
                      INVESTED
                    </span>{" "}
                    ${Math.ceil(order.value * order.orderQuantity).toFixed(0)}
                  </div>{" "}
                  <div>
                    <span style={{ color: "grey", marginRight: "11px" }}>
                      PRICE
                    </span>{" "}
                    ${convertToHumanReadable(order.value)}
                  </div>
                  <div>
                    <span style={{ color: "grey", marginRight: "11px" }}>
                      TOKENS
                    </span>{" "}
                    {order.orderQuantity}
                  </div>
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        color: `${determinePercentageColor({
                          price: price[order.name],
                          value: order.value,
                          strategy: order.type,
                        })}`,

                        marginTop: "10px",
                        fontSize: "3rem",
                      }}
                    >
                      {Math.abs(
                        calculatePercentageChange(
                          price[order.name],
                          order.value
                        )
                      )}
                      %
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PerformingOrdersTiles;
