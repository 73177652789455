import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGear,
  faRightFromBracket,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import "./Menu.css";
import Logo from "../../img/cryptobird_logo.png";
import Click from "../../sounds/click.mp3";

const Menu = ({ setUrl, trial, firstName }) => {
  const navigate = useNavigate();
  const [, removeCookie] = useCookies([]);
  const [navOpen, setNavOpen] = useState(false);

  const playClickSound = () => {
    const audio = new Audio(Click); // Update the path to your audio file
    audio.play();
  };

  function toggleNav() {
    setNavOpen((state) => !state);
  }

  function handleClick(url) {
    // playClickSound();
    const isMenuItemDisabled =
      url === "prospects" ||
      url === "binanceaccount" ||
      url === "kucoinaccount";

    if (trial && isMenuItemDisabled) {
      alert("This feature is not available in demo mode.");
      return;
    }

    setUrl(url);
    toggleNav();
  }

  const handleClickLogo = () => {
    playClickSound();
    setUrl("orders");
    setNavOpen(false);
  };

  const logout = () => {
    localStorage.removeItem("crbd_token");
    removeCookie("token");
    navigate("/login");
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark text-light fixed-top"
      style={{
        background: "#000",
        opacity: 1,
        fontSize: "1rem",
      }}
    >
      <div className="container">
        <div
          className="navbar-brand fw-bold text-light"
          to="/"
          onClick={() => handleClickLogo()}
        >
          <img
            src={Logo}
            alt="logo"
            style={{ width: "60px", marginLeft: "1px" }}
          />
        </div>

        <button
          onClick={toggleNav}
          className={navOpen ? "navbar-toggler" : "navbar-toggler collapsed"}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
          // style={{ marginRight: "15px" }}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={
            navOpen
              ? "collapse navbar-collapse show"
              : "collapse navbar-collapse"
          }
          id="navbarNav"
        >
          <ul className="navbar-nav ms-auto">
            <li
              className="nav-item"
              style={{ marginBottom: "20px" }}
              title="Assets"
            >
              <span
                style={{
                  marginRight: "20px",
                  cursor: "pointer",
                  marginLeft: "20px",
                }}
                onClick={() => handleClick("orders")}
              >
                Assets
              </span>
            </li>
            {!trial && (
              <li
                className="nav-item"
                style={{ marginBottom: "20px" }}
                title="Not available in demo mode."
              >
                <span
                  style={{
                    marginLeft: "30px",
                    cursor: "pointer",
                    color: trial ? "grey" : "#fff",
                  }}
                  onClick={() => handleClick("prospects")}
                >
                  Prospects
                  <span>
                    <sup
                      style={{
                        marginLeft: "3px",
                        background: "green",
                        color: "#fff",
                        paddingLeft: "3px",
                        paddingRight: "3px",
                        borderRadius: "3px",
                      }}
                    >
                      PRO
                    </sup>
                  </span>
                </span>
              </li>
            )}

            <li
              className="nav-item"
              style={{ marginBottom: "20px" }}
              title="Statistics"
            >
              <span
                style={{
                  marginRight: "20px",
                  marginLeft: "20px",
                  cursor: "pointer",
                  color: "#fff",
                }}
                onClick={() => handleClick("statistics")}
              >
                Statistics
              </span>
            </li>
            {!trial && (
              <li className="nav-item dropdown">
                <Link
                  to=""
                  className="nav-link dropdown-toggle ms-2"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ display: "inline", color: trial ? "grey" : "#fff" }}
                  title="Not available in demo mode."
                >
                  Exchanges
                  <span>
                    <sup
                      style={{
                        marginLeft: "3px",
                        background: "green",
                        color: "#fff",
                        paddingLeft: "3px",
                        paddingRight: "3px",
                        borderRadius: "3px",
                      }}
                    >
                      PRO
                    </sup>
                  </span>
                </Link>
                {!trial && (
                  <ul className="dropdown-menu">
                    <li>
                      <span
                        style={{ paddingLeft: "15px", cursor: "pointer" }}
                        onClick={() => handleClick("binanceaccount")}
                      >
                        Binance
                      </span>
                    </li>

                    <li>
                      <span
                        style={{ paddingLeft: "15px", cursor: "pointer" }}
                        onClick={() => handleClick("kucoinaccount")}
                      >
                        Kucoin
                      </span>
                    </li>
                  </ul>
                )}
              </li>
            )}
            <li
              className="nav-item"
              style={{ marginBottom: "20px" }}
              title="Statistics"
            >
              <span
                style={{
                  marginRight: "20px",
                  marginLeft: "20px",
                  cursor: "pointer",
                  color: "#fff",
                }}
                onClick={() => handleClick("faq")}
              >
                FAQ
              </span>
            </li>
            <li className="nav-item dropdown" style={{ marginBottom: "80px" }}>
              <Link
                title="Settings"
                to=""
                className="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{
                  display: "inline",
                  color: "#fff",
                  marginLeft: "20px",
                  outline: "none",
                }}
              >
                Account{" "}
                <span style={{ color: "grey" }}>
                  <FontAwesomeIcon icon={faGear} />
                </span>
              </Link>
              <ul
                className="dropdown-menu fade-in"
                style={{
                  background: "lightgrey",
                  margin: "20px",
                  width: "70%",
                  fontSize: "1.5rem",
                }}
              >
                <li>
                  <span
                    className="dropdown-item"
                    onClick={() => handleClick("usersettings")}
                    style={{ cursor: "pointer", color: "#000" }}
                  >
                    Settings{" "}
                    <span style={{ color: "#000" }}>
                      <FontAwesomeIcon icon={faSliders} />
                    </span>
                  </span>
                </li>

                <li>
                  <span
                    className="dropdown-item"
                    onClick={logout}
                    style={{
                      cursor: "pointer",
                      color: "#000",
                    }}
                  >
                    Logout
                    <span style={{ color: "#000", marginLeft: "10px" }}>
                      <FontAwesomeIcon icon={faRightFromBracket} />
                    </span>
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
