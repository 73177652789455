import { createContext, useContext, useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "./components/PageNotFound";
import "./App.css";
import Login from "./components/Login";
import Dashboard from "./components/dashboard/Dashboard";

// import Home from "./components/Home";
import PostRegister from "./components/elements/PostRegister";
import Register from "./components/Register";
import Results from "./components/analytics/Results";
import userReducer from "./reducers/userReducer";
import Statistics from "./components/dashboard/Statistics";

export const UserContext = createContext({ username: null });

function App() {
  const initialUserState = useContext(UserContext);
  const [state, dispatch] = useReducer(userReducer, initialUserState);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <div>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/results" element={<Results />}></Route>
          <Route path="/statistics" element={<Statistics />}></Route>
          <Route path="/trial" element={<Register />}></Route>
          <Route path="/welcome" element={<PostRegister />}></Route>
          <Route path="/" element={<Login />}></Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </UserContext.Provider>
  );
}

export default App;
