function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN": {
      return { ...state, username: action.payload.username };
    }
    case "LOGOUT": {
      return {
        ...state,
        username: null,
      };
    }
    default:
      return state;
  }
}

export default userReducer;
