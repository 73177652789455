import React, { useEffect, useState } from "react";
import "./Tiles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import InfoTile from "./InfoTile";

const TurnedOnTile = () => {
  const checkTimes = [
    "01:58",
    "02:15",
    "05:58",
    "06:15",
    "09:58",
    "10:15",
    "13:58",
    "14:15",
    "17:58",
    "18:15",
    "21:58",
    "22:15",
    "23:59",
  ];
  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());
  const [showActiveInfo, setShowActiveInfo] = useState(false);

  const handleShowActiveInfo = () => {
    setShowActiveInfo(!showActiveInfo);
  };

  const handleCloseInfo = () => {
    setShowActiveInfo(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function calculateRemainingTime() {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();

    let nearestCheckTime = "";
    let minDifference = Infinity;

    for (const checkTime of checkTimes) {
      const [hour, minute] = checkTime
        .split(":")
        .map((part) => parseInt(part, 10));
      const checkHour = hour < currentHour ? hour + 24 : hour; // Handle times crossing over midnight
      const checkMinute = minute;

      const difference =
        (checkHour - currentHour) * 60 + (checkMinute - currentMinute);
      if (difference >= 0 && difference < minDifference) {
        nearestCheckTime = checkTime;
        minDifference = difference;
      }
    }

    const [nearestHour, nearestMinute] = nearestCheckTime
      .split(":")
      .map((part) => parseInt(part, 10));
    const nearestCheckDate = new Date();
    nearestCheckDate.setHours(nearestHour, nearestMinute, 0, 0);

    const differenceSeconds =
      (nearestCheckDate.getTime() - currentTime.getTime()) / 1000;
    return Math.max(0, Math.ceil(differenceSeconds));
  }

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours === 0 && minutes === 0 && remainingSeconds < 60) {
      // Return blinking "IN PROGRESS" text
      return (
        <span
          style={{
            animation: "blinking 1s infinite",
            fontSize: "1.6rem",
            color: "orange",
          }}
        >
          IN PROGRESS
        </span>
      );
    } else {
      // Return formatted time
      return (
        <span>
          {hours}
          <span style={{ color: "grey" }}>:</span>
          {minutes.toString().padStart(2, "0")}
          <span style={{ color: "grey" }}>:</span>
          {remainingSeconds.toString().padStart(2, "0")}
          {/* <span style={{ color: "grey" }}>s</span> */}
        </span>
      );
    }
  }

  return (
    <div className="container">
      <div
        className="d-flex align-items-center justify-content-center m-3"
        style={{ color: "lightgreen" }}
      ></div>

      <h4 className="mt-3 mb-2" style={{ color: "grey" }}>
        CRYPTOBIRD IS <span style={{ color: "lightgreen" }}>ACTIVE</span>
        <FontAwesomeIcon
          icon={faCircleInfo}
          style={{ marginLeft: "10px", color: "#333", cursor: "pointer" }}
          size="1x"
          onClick={handleShowActiveInfo}
        />
      </h4>
      {showActiveInfo && (
        <div>
          <InfoTile keyword="ACTIVE" onClose={handleCloseInfo} />
        </div>
      )}
      <div
        className="col-12 f-flex"
        style={{ fontSize: "3rem", color: "#fff" }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center bg-dark rounded">
          <div style={{ color: "grey", fontSize: "1rem", marginTop: "20px" }}>
            NEXT MARKET ASSESSMENT
          </div>
          <div
            className="countdown-timer"
            style={{ marginBottom: "20px", color: "#fff" }}
          >
            {formatTime(remainingTime)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TurnedOnTile;
