import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import CryptoBird from "../../img/birdflying.gif";

const PostRegister = () => {
  // Use state to track whether to navigate
  const [shouldNavigate, setShouldNavigate] = React.useState(false);

  // Use useEffect to trigger navigation after 5 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldNavigate(true);
    }, 5000);

    // Cleanup function to clear the timer
    return () => clearTimeout(timer);
  }, []);

  // Render Navigate component if shouldNavigate is true
  if (shouldNavigate) {
    return <Navigate to="/login" />;
  }

  // Render the component content if navigation is not needed yet
  return (
    <div
      className="App-header"
      style={{
        background: "#000",
      }}
    >
      <div className="container text-center">
        <div>
          <img
            src={CryptoBird}
            style={{ height: "300px", marginBottom: "20px" }}
            alt="cryptobird"
          />
        </div>

        <div
          style={{
            padding: "20px",
            textAlign: "center",
            fontSize: "1.2rem",
            color: "#fff",
          }}
        >
          CryptoBird Team has received your request. Once your account is
          activated, you'll receive login instructions.
        </div>
      </div>
    </div>
  );
};

export default PostRegister;
