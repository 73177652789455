import React from "react";
import "./Tiles.css";
import { calculatePercentageChange } from "../../utils/finances";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import kucoinLogo from "../../img/kucoinLogo.png";
import binanceLogo from "../../img/binance.png";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import "./Tiles.css";

const StatisticsTiles = ({ orders }) => {
  const ordersSortedByLatest = orders.sort((a, b) => {
    return new Date(b.closeTime) - new Date(a.closeTime);
  });

  return (
    <div className="container bg-secondary rounded" style={{ padding: "20px" }}>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
        {ordersSortedByLatest.map((order) => (
          <div className="col mb-2 mt-2" key={order._id}>
            <div className="card bg-dark">
              <div className="card-body">
                <div
                  className="mb-2"
                  style={{ fontSize: "0.7rem", color: "lightgrey" }}
                >
                  {order.stream.toUpperCase()}
                </div>
                <div className="d-flex">
                  <div>
                    <h2 className="card-title">
                      {order.name.split("USDT")[0]}
                    </h2>
                  </div>{" "}
                  <div className="d-flex ms-3 align-items-center">
                    <div>
                      <span
                        className={`badge badge bg-dark ${
                          order.type === "short"
                            ? "text-danger"
                            : "text-success"
                        } mb-2`}
                      >
                        {order.type.toUpperCase()}
                      </span>
                      <span style={{ marginLeft: "5px" }}>
                        {order.exchange === "binance" ? (
                          <img src={binanceLogo} alt="binance" height="16px" />
                        ) : (
                          <img src={kucoinLogo} alt="binance" height="15px" />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <span style={{ color: "grey" }}>
                    {order.orderDate.split(" ")[0]}
                  </span>
                  <FontAwesomeIcon
                    icon={faClock}
                    style={{ marginLeft: "15px", color: "grey" }}
                    size="1x"
                  />
                  <span style={{ color: "grey" }}>
                    {" "}
                    {order.orderDate.split(" ")[1]}
                  </span>
                </div>
                {order.stream === "performing" &&
                  order.stopLoss !== 0 &&
                  order.takeProfit !== 0 && (
                    <div>
                      <div style={{ marginTop: "10px" }}>
                        <span style={{ color: "grey", marginRight: "10px" }}>
                          ENTRY
                        </span>{" "}
                        ${order.value.toFixed(6)}
                      </div>
                      {!order.isLongProfit && (
                        <div>
                          <span style={{ color: "grey", marginRight: "10px" }}>
                            TP:
                          </span>{" "}
                          ${order.takeProfit.toFixed(6)}
                          <span style={{ marginLeft: "10px", color: "grey" }}>
                            (
                            {Math.abs(
                              calculatePercentageChange(
                                order.takeProfit,
                                order.value
                              )
                            )}
                            %)
                          </span>
                        </div>
                      )}
                      {order.isLongProfit && (
                        <div>
                          <span style={{ color: "grey", marginRight: "10px" }}>
                            TP:
                          </span>{" "}
                          ${order.takeProfit.toFixed(6)}
                          <span style={{ marginLeft: "10px", color: "grey" }}>
                            (
                            {Math.abs(
                              calculatePercentageChange(
                                order.takeLongProfit,
                                order.value
                              )
                            )}
                            %)
                          </span>
                        </div>
                      )}
                      <div>
                        <span style={{ color: "grey", marginRight: "10px" }}>
                          SL:
                        </span>{" "}
                        ${order.stopLoss.toFixed(6)}
                        <span style={{ marginLeft: "10px", color: "grey" }}>
                          (
                          {Math.abs(
                            calculatePercentageChange(
                              order.stopLoss,
                              order.value
                            )
                          )}
                          %)
                        </span>
                      </div>
                    </div>
                  )}
                {order.stream === "performing" &&
                  order.stopLoss === 0 &&
                  order.takeProfit === 0 && (
                    <div>
                      <div style={{ marginTop: "10px" }}>
                        <span style={{ color: "grey", marginRight: "10px" }}>
                          ENTRY
                        </span>{" "}
                        ${order.value.toFixed(6)}
                      </div>

                      <div>
                        <span style={{ color: "grey", marginRight: "10px" }}>
                          EXIT
                        </span>{" "}
                        ${order.closeValue.toFixed(6)}
                      </div>

                      <div>
                        <span style={{ color: "grey", marginRight: "10px" }}>
                          CHANGE
                        </span>{" "}
                        <span
                          style={{
                            color:
                              calculatePercentageChange(
                                order.closeValue,
                                order.value
                              ) > 0 && order.type === "long"
                                ? "lightgreen"
                                : "#FF8080",
                          }}
                        >
                          {Math.abs(
                            calculatePercentageChange(
                              order.closeValue,
                              order.value
                            )
                          )}
                          %
                        </span>
                      </div>
                    </div>
                  )}
                {["trending", "surfing"].includes(order.stream) && (
                  <div>
                    <div style={{ marginTop: "10px" }}>
                      <span style={{ color: "grey", marginRight: "10px" }}>
                        ENTRY
                      </span>{" "}
                      ${order.value.toFixed(6)}
                    </div>

                    <div>
                      <span style={{ color: "grey", marginRight: "10px" }}>
                        EXIT
                      </span>{" "}
                      ${order.closeValue.toFixed(6)}
                    </div>

                    <div>
                      <span style={{ color: "grey", marginRight: "10px" }}>
                        CHANGE
                      </span>{" "}
                      <span
                        style={{
                          color:
                            calculatePercentageChange(
                              order.closeValue,
                              order.value
                            ) > 0 && order.type === "long"
                              ? "lightgreen"
                              : "#FF8080",
                        }}
                      >
                        {calculatePercentageChange(
                          order.closeValue,
                          order.value
                        )}
                        %
                      </span>
                    </div>
                  </div>
                )}
                <div className="mt-3">
                  <span
                    style={{
                      color: `${order.profit ? "lightgreen" : "#FF8080"}`,
                      marginTop: "15px",
                      fontSize: "2rem",
                    }}
                  >
                    {order.profit
                      ? order.profit && order.status === "MILESTONE"
                        ? "SEC"
                        : "PROFIT"
                      : "LOSS"}
                  </span>

                  <span style={{ marginLeft: "10px", color: "gray" }}>
                    {order.closeTime}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatisticsTiles;
