import React, { useState } from "react";
import "./Faq.css";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const questions = [
    {
      question: "What are the advantages of using CryptoBird?",
      answer:
        "CryptoBird removes emotions from the equation! Unlike humans prone to overconfidence and following the crowd, CryptoBird uses logic and predefined rules to make investment decisions. It continuously monitors the market and executes trades based on these objective criteria, avoiding impulsive choices and herding behavior that can lead to poor investment outcomes.",
    },

    {
      question: "How does CryptoBird work?",
      answer:
        "CryptoBird employs a combination of technical analysis indicators, historical market data, and real-time price feeds to make informed trading decisions. The bot continuously monitors the market for potential trade signals and executes orders based on predefined user settings.",
    },
    {
      question: "Does CryptoBird guarantee profits?",
      answer:
        "No, CryptoBird does not guarantee profits. The cryptocurrency market is highly volatile and unpredictable, and profits are not guaranteed in any trading activity. While CryptoBird utilizes advanced algorithms and historical data analysis to enhance the likelihood of successful trades, users should be aware that all investments carry inherent risks, and there is no assurance of profits. It is essential for users to exercise caution and trade responsibly, considering their individual financial situation and risk tolerance.",
    },
    {
      question: "Is CryptoBird difficult to use?",
      answer:
        "Activating CryptoBird in your account settings is all it takes to get started! This fully automated trading platform utilizes predefined strategies to buy and sell tokens on your behalf.  Demo version allows to explore the interface and evaluate strategies in a risk-free environment. The demo allows you to test the Rebalancer strategy for long-term investment in a single token. Once you're comfortable, the full version unlocks more features and flexibility for managing a diverse crypto portfolio. Get started with CryptoBird and take control of your crypto trading journey.",
    },

    {
      question: "Do I need to place orders myself?",
      answer: "No, CryptoBird automatically places orders on your behalf.",
    },
    {
      question: "What are strategies?",
      answer:
        "CryptoBird takes the emotion out of trading with its predefined strategies! These are like computer programs fueled by math, statistics, and even machine learning.  Constantly analyzing market sentiment and historical data, CryptoBird's strategies go beyond simple rules. They use complex algorithms and machine learning to identify optimal entry and exit points for your tokens, aiming to maximize your returns and improve performance over time.",
    },
    {
      question: "Can I enable/disable strategies?",
      answer: "Yes, but only in the full version of CryptoBird.",
    },
    {
      question: "What is a Rebalancing Strategy?",
      answer:
        "It is a long-term strategy focused on accumulating tokens over time. In this approach, tokens are periodically sold during market downturns to take advantage of lower prices. The proceeds from these sales are then reinvested to purchase more tokens at better prices, allowing the user to accumulate a larger position over time. The goal of rebalancing is to maintain an optimal allocation of assets while maximizing returns in the long run.",
    },
    {
      question: "Is investing in Crypto safe?",
      answer:
        "CryptoBird uses automation to limit emotional investing, but remember, cryptocurrency markets are inherently volatile and unpredictable. Even with well-designed strategies, CryptoBird can't guarantee profits and faces risks like market swings, limited historical data for future predictions, potential software issues, and external events impacting the entire crypto space.  Before using CryptoBird, carefully consider these risks and only invest what you can afford to lose.",
    },
    {
      question: "What affects cryptocurrency prices?",
      answer:
        "Cryptocurrency prices are influenced by a complex interplay of factors. Just like any asset, supply and demand play a major role. Limited supply with high demand drives prices up.  Beyond that, market sentiment, regulations, media coverage, and even everyday adoption by businesses all influence how much people are willing to pay for a particular cryptocurrency. This can lead to high volatility, with prices fluctuating significantly based on these ever-changing dynamics.",
    },
    {
      question: "What is a Market Sentiment?",
      answer:
        "Market sentiment reflects the overall mood of cryptocurrency investors at a given time.  CryptoBird uses various indicators based on Bitcoin's daily price data to gauge this sentiment. These indicators can reveal whether the market is generally trending upwards (bullish, green color), downwards (bearish, red color), or experiencing a period of sideways movement (neutral, grey color). The CryptoBird platform provides market sentiment data spanning the last five days, represented as Bitcoin coins, with each coin corresponding to one day. By analyzing market sentiment, CryptoBird can make well-informed decisions and implement appropriate strategies.",
    },
    {
      question: "How to interprete CryptoBird's Performance?",
      answer:
        "The 'Performance' section gives you a quick view of CryptoBird's results. 'Portfolio' shows the profit/loss on tokens currently held in your CryptoBird portfolio, reflecting the performance of your actively managed assets.  'Total Capital Gain' combines the value of your current holdings with any past trading profits or losses, giving you a complete picture of your investment's performance through CryptoBird.",
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="faq-accordion container">
      <h1 style={{ color: "grey", marginTop: "20px", marginBottom: "20px" }}>
        CryptoBird's <span style={{ color: "#fff" }}>FAQ</span>:
      </h1>
      {questions.map((item, index) => (
        <div className="faq-item" key={index}>
          <h3 className="faq-header">
            <button
              className={`faq-button ${index === activeIndex ? "active" : ""}`}
              type="button"
              onClick={() => toggleAccordion(index)}
            >
              {item.question}
            </button>
          </h3>
          <div
            className={`faq-collapse ${index === activeIndex ? "show" : ""}`}
          >
            <div className="faq-body">
              <p>{item.answer}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Faq;
