import React, { useState, useEffect } from "react";
import {
  getUserPortfolio,
  getPrices,
  getMarketSentiment,
} from "../../utils/dataService";
import { calculateTotalFinances } from "../../utils/finances";
import TokenCircle from "../elements/TokenCircle";
import Spinner from "../../img/anime10.gif";
import "./Orders.css";
import SurfingOrdersTiles from "../elements/SurfingOrdersTiles";
import PerformingOrdersTiles from "../elements/PerformingOrdersTiles";
import TrendingOrdersTiles from "../elements/TrendingOrdersTiles";
// import GamingSectorTiles from "../elements/GamingSectorTiles";
// import AiSectorTiles from "../elements/AiSectorTiles";
import FundingTokenChart from "../elements/FundingTokenChart";
import AssetTile from "../elements/AssetTile";
import InfoTile from "../elements/InfoTile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import StatusTile from "../elements/StatusTile";
import TurnedOnTile from "../elements/TurnedOnTile";
import SentimentTile from "../elements/SentimentsTile";

const Orders = ({ userId, trial, setUrl, firstName = "" }) => {
  const [userPortfolio, setUserPortfolio] = useState(null);
  const [balances, setBalances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [experience, setExperience] = useState("Beginner");
  const [sentiments, setSentiments] = useState([]);
  const [trendTotalPnl, setTrendTotalPnl] = useState(0);
  const [surfTotalPnl, setSurfTotalPnl] = useState(0);
  // const [aiTotalPnl, setAITotalPnl] = useState(0);
  // const [gamingTotalPnl, setGamingTotalPnl] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [totalFinances, setTotalFinances] = useState({
    totalAssets: 0,
    debt: 0,
    pnl: 0,
  });
  const [showStratey, setShowStrategy] = useState(false);
  const [showChartInfo, setShowChartInfo] = useState(false);
  const [isCryptoBirdActivated, setIsCryptoBirdActivated] = useState(false);

  function handleClick(url) {
    setUrl(url);
  }

  const handleShowChartInfo = () => {
    setShowChartInfo(!showChartInfo);
  };

  const handleShowStrategy = () => {
    setShowStrategy(!showStratey);
  };

  const handleCloseInfo = () => {
    setShowStrategy(false);
    setShowChartInfo(false);
  };

  const refreshData = async () => {
    console.log("Force refresh data");
    setIsRefreshing(true);
    const { data: response } = await getPrices();
    const { data: price } = response;
    const { data } = await getUserPortfolio(userId);
    console.log("data", data);

    const {
      userPortfolio,
      userVault,
      userBalances,
      userTradingExperience,
      userInitialInvestment,
    } = data;

    setExperience(userTradingExperience);
    setUserPortfolio(userPortfolio);
    setBalances(userBalances);
    const totalFinancesUpdate = calculateTotalFinances({
      userPortfolio,
      userVault,
      price,
      userInitialInvestment,
    });

    setTotalFinances(totalFinancesUpdate);
    setIsRefreshing(false);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      const { data: response } = await getPrices();
      const { data: price } = response;
      const { data } = await getUserPortfolio(userId);
      const {
        userPortfolio,
        userVault,
        userBalances,
        userTradingExperience,
        cryptoBirdActivated,
        userInitialInvestment,
      } = data;

      const { data: sentimets } = await getMarketSentiment();
      setSentiments(sentimets.sentiments);
      setExperience(userTradingExperience);
      setUserPortfolio(userPortfolio);
      setBalances(userBalances);
      setIsCryptoBirdActivated(cryptoBirdActivated);

      const totalFinancesUpdate = calculateTotalFinances({
        userPortfolio,
        userVault,
        price,
        userInitialInvestment,
      });

      setTotalFinances(totalFinancesUpdate);
      setLoading(false);
    };

    fetchOrders();

    // Set up a timer to fetch data every 5 minutes
    const intervalId = setInterval(() => {
      fetchOrders();
    }, 2 * 60 * 1000); // 2 minutes in milliseconds
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (loading) {
    return (
      <div
        className="container mt-3 d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <img src={Spinner} alt="spinner" height="400px" />
      </div>
    );
  }

  const {
    trendingTokens,
    performingTokens,
    surfingTokens,
    aiTokens,
    gamingTokens,
  } = userPortfolio;

  const activeTreandingTokens = trendingTokens.filter(
    ({ active }) => active === true
  );
  const activePerformingTokens = performingTokens.filter(
    ({ active }) => active === true
  );
  const activeSurfingTokens = surfingTokens.filter(
    ({ active }) => active === true
  );

  const countUniqueTokens = (balances) => {
    // Initialize an empty object to store token names
    const uniqueTokens = {};

    // Iterate over the balances array
    balances.forEach(({ token }) => {
      // Get the token name from the current balance object
      const tokenName = token.name;

      // Add the token name to the uniqueTokens object
      uniqueTokens[tokenName] = true;
    });

    // Count the number of keys in uniqueTokens object
    const numberOfTokens = Object.keys(uniqueTokens).length;

    return numberOfTokens;
  };

  const advancedLayout =
    experience !== "Beginner" && experience !== "Intermediate";

  return (
    <div className="container">
      {!isCryptoBirdActivated && (
        <StatusTile
          handleClick={handleClick}
          isCryptoBirdActivated={isCryptoBirdActivated}
        />
      )}
      {isCryptoBirdActivated && <TurnedOnTile targetTime="23:00" />}

      <div className="container mt-5">
        <h3 style={{ color: "grey", marginBottom: "2px" }}>MARKET SENTIMENT</h3>
        <SentimentTile sentiments={sentiments} />
      </div>
      <div className="container mt-5">
        <h3 style={{ color: "grey", marginBottom: "2px" }}>YOUR PORTFOLIO</h3>
      </div>
      <div className="col-12">
        <AssetTile
          title="Performance"
          value={totalFinances.pnl}
          refresh={refreshData}
          isRefreshing={isRefreshing}
          totalAssets={totalFinances.totalAssets}
          totalDebt={totalFinances.debt}
        />
      </div>
      <div className="col-12">
        <AssetTile
          title="Total Capital Gain"
          value={
            totalFinances.totalAssets -
            totalFinances.debt -
            totalFinances.userInitialInvestment
          }
          totalAssets={totalFinances.totalAssets}
          userInitialInvestment={totalFinances.userInitialInvestment}
          totalDebt={totalFinances.debt}
          refresh={refreshData}
          isRefreshing={isRefreshing}
        />
      </div>
      <div className="container mt-5">
        <h3 style={{ color: "grey", marginBottom: "2px" }}>
          YOUR ASSETS VALUE
        </h3>
      </div>
      <div className="row">
        <div className="col-12 col-sm-4">
          <AssetTile
            title="Total"
            value={totalFinances.totalAssets}
            refresh={refreshData}
            isRefreshing={isRefreshing}
          />
        </div>
        {advancedLayout && (
          <div className="col-12 col-sm-4">
            <AssetTile
              title="Borrowed"
              value={-totalFinances.debt}
              refresh={refreshData}
              isRefreshing={isRefreshing}
            />
          </div>
        )}
        {advancedLayout && (
          <div className="col-12 col-sm-4">
            <AssetTile
              title="Net"
              value={totalFinances.totalAssets - totalFinances.debt}
              refresh={refreshData}
              isRefreshing={isRefreshing}
            />
          </div>
        )}
      </div>
      {balances.length > 0 && (
        <div className="container mt-5 mb-3">
          <div
            className="d-flex justify-content-center"
            style={{ color: "grey", fontSize: "1.2rem", marginBottom: "3rem" }}
          >
            LONG-TERM STRATEGIES
          </div>
        </div>
      )}

      {balances.length > 0 && (
        <div className="container">
          <h3 className="mt-2">
            Rebalancing Tokens
            <span style={{ color: "grey", marginLeft: "10px" }}>
              {countUniqueTokens(balances)}
            </span>
            <span>
              <FontAwesomeIcon
                icon={faCircleInfo}
                style={{
                  marginLeft: "10px",
                  color: "#333",
                  cursor: "pointer",
                }}
                size="1x"
                onClick={handleShowStrategy}
              />
            </span>
          </h3>
          {showStratey && (
            <div className="row">
              <InfoTile keyword="REBALANCING" onClose={handleCloseInfo} />
            </div>
          )}
          {balances.length === 0 && (
            <div className="badge text-light" style={{ background: "#333" }}>
              No signals yet
            </div>
          )}

          <div className="d-flex mb-5">
            <div style={{ marginRight: "10px" }}>
              <TokenCircle
                name={balances[0].token.name}
                isActive={balances[balances.length - 1].token.isActive}
              />
            </div>
          </div>

          <div>
            <h3 className="mt-5">
              <span style={{ color: "grey" }}>
                {balances[0].token.name.split("USDT")[0]} Rebalancer
              </span>{" "}
              {balances[balances.length - 1].token.qty === 0 ? (
                "Waiting"
              ) : (
                <span style={{ color: "lightgreen" }}>Active</span>
              )}{" "}
              <span>
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{
                    marginLeft: "10px",
                    color: "#333",
                    cursor: "pointer",
                  }}
                  size="1x"
                  onClick={handleShowChartInfo}
                />
              </span>
            </h3>
            {showChartInfo && (
              <div className="row">
                <InfoTile keyword="CHART" onClose={handleCloseInfo} />
              </div>
            )}

            <div className="d-flex justify-content-center">
              <FundingTokenChart balances={balances} />
            </div>
          </div>
        </div>
      )}
      <div className="container mt-5">
        <div
          className="d-flex justify-content-center"
          style={{ color: "grey", fontSize: "1.2rem", marginBottom: "3rem" }}
        >
          MEDIUM-TERM STRATEGIES
        </div>
      </div>
      <div className="Orders-header mb-4">
        <PerformingOrdersTiles orders={activePerformingTokens} />
      </div>
      <div className="Orders-header mb-4">
        <TrendingOrdersTiles
          orders={activeTreandingTokens}
          setTrendTotalPnl={setTrendTotalPnl}
        />
      </div>
      <div className="Orders-header  mb-4">
        <SurfingOrdersTiles
          orders={activeSurfingTokens}
          setSurfTotalPnl={setSurfTotalPnl}
        />
      </div>
    </div>
  );
};

export default Orders;
