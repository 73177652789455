import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBitcoin } from "@fortawesome/free-brands-svg-icons";

const SentimentTile = ({ sentiments }) => {
  const btcMarketSentiment = sentiments[0].indicators[0].btcMarketSentiment;

  let headercolor = "lightgrey";
  if (btcMarketSentiment === "bullish") headercolor = "lightgreen";
  if (btcMarketSentiment === "neutral") headercolor = "lightgrey";
  if (btcMarketSentiment === "bearish") headercolor = "#FF8080";

  return (
    <div
      className="text-center bg-dark"
      style={{
        borderRadius: "0.5rem",
        padding: "15px",
        height: "170px",
      }}
    >
      <div style={{ color: headercolor, fontSize: "1rem" }}>
        {btcMarketSentiment.toUpperCase()}
      </div>

      <div className="d-flex justify-content-center mt-4">
        {sentiments.length > 0 &&
          sentiments.map((sentiment, index) => {
            const { btcMarketSentiment } = sentiment.indicators[0];

            let color = "lightgrey";
            if (btcMarketSentiment === "bullish") color = "lightgreen";
            if (btcMarketSentiment === "neutral") color = "lightgrey";
            if (btcMarketSentiment === "bearish") color = "#FF8080";

            return (
              <FontAwesomeIcon
                key={index}
                icon={faBitcoin}
                style={{
                  color: color,
                  marginRight: "3px",
                  fontSize: "35px",
                }}
              />
            );
          })}
      </div>
    </div>
  );
};

export default SentimentTile;
