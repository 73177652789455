import http from "./httpService";

const apiPublicServer = process.env.REACT_APP_PUBLIC_BACKEND;

export function getSignals() {
  return http.get(apiPublicServer);
}

export function getPropsects() {
  return http.get(`${apiPublicServer}prospects`);
}

export function getTrendingProspects() {
  return http.get(`${apiPublicServer}trendingProspects`);
}

export function getSurfingProspects() {
  return http.get(`${apiPublicServer}surfingProspects`);
}

export function getOrders() {
  return http.get(`${apiPublicServer}orders`);
}

export function getTrendingOrders() {
  return http.get(`${apiPublicServer}trendingOrders`);
}

export function getUserPortfolio(userId) {
  return http.post(`${apiPublicServer}userPortfolio`, { userId: userId });
}

export function getAIOrders() {
  return http.get(`${apiPublicServer}aiOrders`);
}

export function getGamingOrders() {
  return http.get(`${apiPublicServer}gamingOrders`);
}

export function getPerfomingOrders() {
  return http.get(`${apiPublicServer}performingOrders`);
}

export function getSurfingOrders() {
  return http.get(`${apiPublicServer}surfingOrders`);
}

export function getFundingAssetsBalances() {
  return http.get(`${apiPublicServer}fundingAssetsBalances`);
}

export function getStatistics() {
  return http.get(`${apiPublicServer}statistics`);
}

export function getDailyData(date, performingChecked, trendingChecked, surfingChecked) {
  return http.post(`${apiPublicServer}dailyData`, {
    date: date,
    performingChecked: performingChecked,
    trendingChecked: trendingChecked,
    surfingChecked: surfingChecked,
  });
}

export function getWeeklyData(date, performingChecked, trendingChecked, surfingChecked) {
  return http.post(`${apiPublicServer}weeklyData`, {
    date: date,
    performingChecked: performingChecked,
    trendingChecked: trendingChecked,
    surfingChecked: surfingChecked,
  });
}

export function getMonthlyData(date, performingChecked = true, trendingChecked = true, surfingChecked = true) {
  return http.post(`${apiPublicServer}monthlyData`, {
    date: date,
    performingChecked: performingChecked,
    trendingChecked: trendingChecked,
    surfingChecked: surfingChecked,
  });
}

export function testDateRange(date) {
  return http.post(`${apiPublicServer}testDateRange`, { date: date });
}

export function findDateRangeProspects(data) {
  return http.post(`${apiPublicServer}findDateRangeProspects`, data);
}

export function getBinanceWalletInfo() {
  return http.get(`${apiPublicServer}binance`);
}
export function getKucoinTickers() {
  return http.post(`${apiPublicServer}kucoinTickers`);
}

export function getKucoinWalletInfo() {
  return http.get(`${apiPublicServer}kucoin`);
}

export function getKucoinLiabilities() {
  return http.get(`${apiPublicServer}liabilitiesKucoin`);
}

export function getCurrentPrice(symbol) {
  return http.post(`${apiPublicServer}currentPrice`, { symbol: symbol });
}

export function getPrices() {
  return http.get(`${apiPublicServer}prices`);
}

export function getMarketSentiment() {
  return http.get(`${apiPublicServer}marketSentiment`);
}
