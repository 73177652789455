// import "../../App.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import "./dashboard.css";
import { authUser, verifyToken } from "../../utils/authService";
import LiveDashboard from "./LiveDashboard";
import DemoDashboard from "./DemoDashboard";

const Dashboard = () => {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);
  const [trial, setTrial] = useState(false);
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [userId, setUserId] = useState("");
  const [vault, setVault] = useState([]);
  const [demoLeverage, setDemoLeverage] = useState(1);
  const [isCryptoBirdActivated, setIsCryptoBirdActivated] = useState(false);

  useEffect(() => {
    const verifyTokenLocally = async () => {
      const crdbToken = localStorage.getItem("crbd_token");

      if (!crdbToken) {
        navigate("/login");
        return;
      }

      try {
        // Use Axios to send a request to your backend to verify the token
        const response = await verifyToken(crdbToken);

        const {
          status,
          username,
          firstName,
          trial,
          id,
          vault,
          demoLeverage,
          isCryptoBirdActivated,
        } = response.data;

        if (status) {
          setUsername(username);
          setTrial(trial);
          setUserId(id);
          setVault(vault);
          setDemoLeverage(demoLeverage);
          setIsCryptoBirdActivated(isCryptoBirdActivated);
          setFirstName(firstName);
          console.log(`Authenticated: ${username}`);
        } else {
          // If the token is not valid or the user is not authenticated, navigate to login page
          navigate("/login");
          // Clear the token from localStorage
          localStorage.removeItem("crbd_token");
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        // Handle error
      }
    };

    verifyTokenLocally();
  }, [navigate, verifyToken]); // Add verifyToken as a dependency if it's used within the effect

  if (userId) {
    if (trial) {
      return (
        <DemoDashboard
          username={username}
          firstName={firstName}
          trial={trial}
          userId={userId}
          vault={vault}
          demoLeverage={demoLeverage}
          isCryptoBirdActivated={isCryptoBirdActivated}
        />
      );
    } else {
      return (
        <LiveDashboard username={username} trial={trial} userId={userId} />
      );
    }
  } else {
    return <div style={{ padding: "10px" }}>Authenticating...</div>;
  }
};

export default Dashboard;
