import React from "react";

const StatusTile = ({ handleClick, isCryptoBirdActivated }) => {
  return (
    <div className="container">
      <div
        className=" col-12 mt-1"
        style={{
          fontSize: "4rem",
          color: "#FFF",
        }}
        //onClick={() => handleClick("usersettings")}
      >
        <div
          style={{
            backgroundColor: "#FF8080",
            borderRadius: "0.5rem",
            padding: "15px",
            textAlign: "center",
            color: "#000",
          }}
        >
          <div> {isCryptoBirdActivated ? "ON" : "DISABLED"}</div>
        </div>

        <div
          className="mt-2"
          style={{
            color: "grey",
            fontSize: "1rem",
            textAlign: "center",
          }}
        >
          Get started with our{" "}
          <span style={{ color: "#fff" }} onClick={() => handleClick("faq")}>
            FAQ
          </span>{" "}
          first, then visit{" "}
          <span
            style={{ color: "#fff" }}
            onClick={() => handleClick("usersettings")}
          >
            ACCOUNT
          </span>{" "}
          to enable CryptoBird
        </div>
      </div>
    </div>
  );
};

export default StatusTile;
