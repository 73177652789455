import React from "react";
import Spinner from "../../img/ball.gif";

const Welcome = () => {
  return (
    <div className="contaier d-flex justify-content-center flex-column">
      <div className="container d-flex justify-content-center align-items-center">
        <img
          src={Spinner}
          alt="spinner"
          style={{ height: "600px", border: "1px solid lightgrey", marginTop: "100px" }}
        />
      </div>
      <div style={{ color: "lightgrey", fontSize: "4rem", textAlign: "center", marginTop: "20px" }}>
        C r y p t o B i r d{" "}
      </div>
    </div>
  );
};

export default Welcome;
