import React from "react";
import "./Tiles.css";

const InfoTile = ({ keyword, onClose }) => {
  console.log("keyword", keyword);
  let description = "";

  switch (keyword) {
    case "SHORT":
      description =
        "you make money when the price of the token goes down. In short selling, you borrow cryptocurrency, sell it at the current price, and aim to buy it back at a lower price later. If successful, you return the borrowed cryptocurrency and profit from the difference. However, if the price goes up, you may face losses.";
      break;
    case "LONG":
      description = "you make money when the price of the token goes up.";
      break;
    case "PERFORMING":
      description =
        "this strategy responds rapidly to market changes and carries higher risk. It enables immediate participation in market movements from the outset, relying on automated technical analysis and Bitcoin market sentiment. Executing this strategy offers the benefit of quicker market involvement";
      break;
    case "TRENDING":
      description =
        "the Trending Strategy, while not as swift as the Performing Strategy, may require users to wait longer to participate actively. However, it offers greater reliability, as it meticulously identifies and capitalizes on sustained market trends, providing a more stable approach to investment. It's based on automated technical analysis and Bitcoin market sentiment";
      break;
    case "SURFING":
      description =
        "is a strategy that follows the trend, aiming to buy tokens when their prices briefly dip. It involves seizing opportunities during market fluctuations, purchasing assets at lower prices before they resume their upward trajectory with the overall trend. It's based on the principle that the trend is your friend";
      break;
    case "REBALANCING":
      description =
        "is a long-term strategy focused on accumulating tokens over time. In this approach, tokens are periodically sold during market downturns to take advantage of lower prices. The proceeds from these sales are then reinvested to purchase more tokens at better prices, allowing the user to accumulate a larger position over time. The goal of rebalancing is to maintain an optimal allocation of assets while maximizing returns in the long run";
      break;
    case "TOKENS":
      description =
        "select a token you anticipate will appreciate in value. CryptoBird will commence monitoring it using predefined algorithms and execute purchases when the timing is favorable, thereby optimizing your investment potential";
      break;
    case "SINGLE INVESTMENT":
      description =
        "is the maximum amount of money you're willing to put into a single transaction. It's your personal limit, ensuring you don't invest more than you're comfortable with in any one opportunity. By setting this boundary, you maintain control over your investments and reduce the risk of overcommitting financially. It's a key part of managing your investment portfolio and safeguarding your financial well-being";
      break;
    case "BUDGET ALLOCATION":
      description =
        "involves dividing your total funds across different investment strategies to manage risk effectively. Each strategy receives a portion of your budget, ensuring a balanced approach to investing. CryptoBird provides real-time updates on how your funds are allocated, allowing you to monitor your investments and adjust your strategy as needed. This helps you maintain a diversified portfolio and optimize your returns while minimizing risk";
      break;
    case "LEVERAGE":
      description =
        "means using borrowed money to potentially double your funds, profits, and losses in an investment or project. For example, if you invest $100 and use leverage of 2, it means you're essentially investing $200 ($100 of your own money and $100 borrowed)";
      break;
    case "Avg Risk":
      description =
        "is a metric that calculates the average risk based on the last 30 orders that were closed with a loss. It provides insight into the potential losses one might expect in trading or investing activities";
      break;
    case "ACTIVE":
      description =
        "status indicates that CryptoBird is actively working on your behalf. It operates autonomously, conducting evaluations of the crypto market every 4 hours (refer to the timer below for the next market involvement). Using automated systems and predefined strategies, it identifies promising tokens, adds them to a watch list, and executes orders when favorable opportunities arise. This fully automated approach enables efficient market analysis and potential profit generation";
      break;
    case "CHART":
      description =
        "After selecting a token for the Rebalancing strategy, the chart displays the value of the selected token in two variants: an Orange line and a Blue line. The Orange line represents the value of the token if you bought it right away after adding it to your portfolio, essentially employing a 'buy and hodl' (Hold On for Dear Life) strategy, while the Blue line illustrates the token value when using the Rebalancer. The value may be zero if CryptoBird has not purchased the token yet (indicated by a grey background for the token circle), or it may be lower/higher than HODL if the order has been placed. Ideally, the Blue line should be above the Orange one";
      break;
    default:
      description = "you make money when the price of a token goes up.";
      break;
  }

  return (
    <div
      className="container fade-in"
      // style={{
      //   background: "#000",
      // }}
    >
      <div className="d-flex">
        <div
          style={{
            background: "#bfe5bf",
            width: "100%",
            color: "#000",
            padding: "15px",
            opacity: 0.99,
            borderRadius: "10px",
            marginBottom: "10px",
          }}
        >
          <div className="badge bg-dark me-2">{keyword}</div>
          {description}
          <div className="d-flex justify-content-end mt-3">
            <div className="badge bg-light me-3 text-dark" onClick={onClose}>
              CLOSE
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoTile;
