import React from "react";

const Footer = () => {
  return (
    <footer className="footer mt-5 mb-1">
      <div className="container text-center">
        <span style={{ color: "#333" }}>&copy; CryptoBird 2024</span>
      </div>
    </footer>
  );
};

export default Footer;
